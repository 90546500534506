import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { NgVoxloudModule } from "node_modules/ng-voxloud";
import { LangChangeEvent, TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";

import { environment } from 'src/environments/environment';
import { layouts } from "./layout";
import { pipes } from './pipes';
import { components } from './components';
import { directives } from './directives';
import { ReviewComponent } from './components/review/layout/review.component';
import { VoxReviewWidgetModule } from "node_modules/ng-voxloud";


@NgModule({
  declarations: [
    ...layouts,
    ...components,
    ...directives,
    ...pipes,
    ReviewComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NgVoxloudModule.forRoot({
      apiUrl: environment.legacyEndpoint,
      production: environment.production
    }),
    VoxReviewWidgetModule
  ],
  exports: [
    ...layouts,
    ...components,
    ...directives,
    ...pipes,
    TranslateModule
  ]
})
export class SharedModule {
}
