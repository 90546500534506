import { ActionReducerMap } from "@ngrx/store";
import { State, reducer } from "./starter-kit-reducer";

export interface StarterKitState {
  status: State;
}

export const starterKitReducer: ActionReducerMap<StarterKitState> = {
  status: reducer
};
