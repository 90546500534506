import { State, reducer } from './pay.reducers';
import { ActionReducerMap } from '@ngrx/store';

export * from './pay.reducers';

export interface PayState {
  payStatus: State;
}

export const payReducer: ActionReducerMap<PayState> = {
  payStatus: reducer
};
