import { State, reducer } from "./app.reducers";
import { ActionReducerMap } from "@ngrx/store";

export interface AppState {
  reducer: State;
}

export const AppReducer: ActionReducerMap<AppState> = {
  reducer: reducer
};

export * from "./app.reducers";
