import { Account } from '../../models';
import { ActivationActions, ActivationActionsTypes } from '../actions';

export interface State {
  account: Account;
  requestId: string;
  newNumber: string;
  loading: boolean;
  error: any;
}

export const initialState: State = {
  account: null,
  requestId: null,
  newNumber: null,
  loading: false,
  error: null
};

export function reducer(state = initialState, action: ActivationActions) {
  switch (action.type) {
    case ActivationActionsTypes.sendAccount: {
      return {
        ...state,
        account: action.payload,
        loading: true
      };
    }
    case ActivationActionsTypes.sendAccountSuccess: {
      return {
        ...state,
        requestId: action.payload.request_id,
        account: action.payload,
        loading: false
      };
    }
    case ActivationActionsTypes.sendAccountFailure: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case ActivationActionsTypes.setNewNumber: {
      return {
        ...state,
        newNumber: action.payload,
        loading: false
      };
    }

    default:
      break;
  }
}
