import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TokenModel } from "src/app/features/welcome-specialist/layout";
import { Subscription } from "rxjs";
import { TimerService } from "../../services/timer.service";
import { AppFacade } from "src/app/domains/app/store";
import { formatDate } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "onb-timer-main",
  templateUrl: "./timer-main.component.html",
  styleUrls: ["./timer-main.component.scss"]
})
export class TimerMainComponent implements OnInit {
  public tokenSubscription: Subscription;
  // variables to manage token from url
  public tokenString: string;
  public token: TokenModel = null;

  public expiresDateMillis: number;
  public currentDateMillis: number = new Date().getTime();
  public daysLeft: number;
  public hoursLeft: number;
  public minutesLeft: number;
  public secondsLeft: number;
  public countdownInterval = null;

  // variables to sync view with logic
  public changeDaysTens: boolean = false;
  public changeDaysUnit: boolean = false;
  public changeHoursTens: boolean = false;
  public changeHoursUnit: boolean = false;
  public changeMinutesTens: boolean = false;
  public changeMinutesUnit: boolean = false;
  public changeSecondsTens: boolean = false;
  public changeSecondsUnit: boolean = false;

  public shown: boolean = false;

  constructor(
    private timerSerivce: TimerService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.timerSerivce.getShown().subscribe((shown: boolean) => {
      this.shown = shown;
    });

    this.timerSerivce.getToken().subscribe((token: TokenModel) => {
      this.token = token;
      this.token.date = new Date(token.date);
      this.expiresDateMillis = this.token.date.getTime();
      this.setRemainingTime();
      this.countTime();
    });
  }

  // Methods for number format
  getTensDigit(p) {
    return Math.floor(p / 10);
  }
  getUnitNumber(p) {
    return +(p % 10).toString();
  }

  getFormatDate(date: Date) {
    var options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    };
    var newDate;
    if (this.translate.currentLang === "it") {
      newDate = new Date(date).toLocaleDateString("it-IT", options);
    }
    if (this.translate.currentLang === "en") {
      newDate = new Date(date).toLocaleDateString("en-US", options);
    }
    return newDate;
  }

  // Methods to manage timer
  // calculate days, hours, minutes, seconds
  setRemainingTime() {
    var delta =
      Math.abs(this.expiresDateMillis - this.currentDateMillis) / 1000;

    this.daysLeft = Math.floor(delta / 86400);
    delta -= this.daysLeft * 86400;

    this.hoursLeft = Math.floor(delta / 3600) % 24;
    delta -= this.hoursLeft * 3600;

    this.minutesLeft = Math.floor(delta / 60) % 60;
    delta -= this.minutesLeft * 60;

    this.secondsLeft = Math.floor(delta % 60);
  }

  countTime() {
    this.countdownInterval = setInterval(() => {
      // manage seconds
      this.changeSecondsUnit = true;
      if (this.getUnitNumber(this.secondsLeft) === 0) {
        this.changeSecondsTens = true;
      } else {
        this.changeSecondsTens = false;
      }
      this.secondsLeft = this.secondsLeft - 1;
      // manage minutes
      if (this.minutesLeft >= 0 && this.secondsLeft < 0) {
        this.secondsLeft = 59;
        this.changeMinutesUnit = true;
        if (this.secondsLeft === 0) {
          this.changeMinutesTens = true;
        } else {
          this.changeMinutesTens = false;
        }
        this.minutesLeft = this.minutesLeft - 1;
      } else {
        this.changeMinutesUnit = false;
      }
      // manage hours
      if (this.hoursLeft >= 0 && this.minutesLeft < 0) {
        this.minutesLeft = 59;
        this.changeHoursUnit = true;
        if (this.minutesLeft === 0) {
          this.changeHoursTens = true;
        } else {
          this.changeHoursTens = false;
        }
        this.hoursLeft = this.hoursLeft - 1;
      } else {
        this.changeHoursUnit = false;
      }
      // manage days
      if (this.daysLeft >= 0 && this.hoursLeft < 0) {
        this.hoursLeft = 24;
        this.changeDaysUnit = true;
        if (this.hoursLeft === 0) {
          this.changeDaysTens = true;
        } else {
          this.changeDaysTens = false;
        }
        this.daysLeft = this.daysLeft - 1;
      } else {
        this.changeDaysUnit = false;
      }
    }, 1000);
  }
}
