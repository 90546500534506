import { AddonActions, AddonActionsTypes } from "../actions";
import { Addons } from "../../model";

export interface State {
  addons: Addons;
}

export const initialState: State = {
  addons: []
};

export function reducer(
  state: State = initialState,
  action: AddonActions
): State {
  switch (action.type) {
    case AddonActionsTypes.storeAddons: {
      return {
        ...state,
        addons: [...state.addons, ...action.payload]
      };
    }
    case AddonActionsTypes.storeNumberAddon: {
      const addons = state.addons.filter(el =>
        !el.id.includes('-phone_number#national-free'));
      if (action.payload) {
        addons.push(action.payload);
      }
      return {
        ...state,
        addons: [...addons]
      }
    }
    case AddonActionsTypes.storeSkAddons: {
      const addons = state.addons.filter(el =>
        !el.id.includes('-phone_rental'));
      if (action.payload && action.payload.length > 0) {
        addons.push(...action.payload)
      }
      return {
        ...state,
        addons: [...addons]
      }
    }
    default:
      return state;
  }
}
