import { Action } from '@ngrx/store';
import { VDValues } from 'ng-voxloud';
import { VerificationDataResponse } from 'src/app/domains/info/models/info.model';
import { PortabilityData, PortabilityDocuments, PortabilityNumber } from '../../models/portability.model';


export enum AccountDataActionTypes {
  SaveVerificationDataRequirements = '[Verification Data] Save Verification Data Requirements',
  SaveVerificationData = '[Verification Data] Save Verification Data',
  savePortabilityDocuments = '[Portability] Save Portability Documents'
}


export class SaveVerificationDataRequirements implements Action {
  readonly type = AccountDataActionTypes.SaveVerificationDataRequirements;
  constructor(readonly payload: VerificationDataResponse) { }
}

export class SaveVerificationData implements Action {
  readonly type = AccountDataActionTypes.SaveVerificationData;
  constructor(readonly payload: { vd: VDValues, type: string }) { }
}

export class savePortabilityDocuments implements Action{
  readonly type = AccountDataActionTypes.savePortabilityDocuments;
  constructor(readonly payload: PortabilityData){}
}

export type AccountDataActions = SaveVerificationDataRequirements
  | SaveVerificationData
  | savePortabilityDocuments;