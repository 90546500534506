import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { environment } from "src/environments/environment";
import { ReferrerDto } from '../model';
import { Injectable } from "@angular/core";

@Injectable()
export class SignupService {
  constructor(private http: HttpClient) {}

  checkEmailExists(email: string) {
    return this.http.post(
      `${environment.legacyEndpoint}/api/v2/onboarding/check/email`,
      { email: email },
      {
        observe: "response"
      }
    );
  }

  getReferrerId(referrerId: string): Observable<ReferrerDto> {
    return this.http.get<ReferrerDto>(`${environment.legacyEndpoint}/api/v1/referrers/${referrerId}`);
  }
}
