<div class="main-container">
  <div class="quantity-container">
    <button
      class="vox-btn vox-btn-secondary"
      (click)="deleteItem()"
      [disabled]="minimumNumber"
    >
      <i class="icon-small-remove"></i>
    </button>
    <div class="user-number">
      <input
        class="vox-text-field"
        type="number"
        [value]="quantity"
        (keydown.enter)="onKeydown($event)"
        (keydown.tab)="onKeydown($event)"
        (keydown)="onPreventDefault($event)"
        (input)="onInsertNumber($event)"
        (blur)="onBlurMethod($event)"
      />
    </div>
    <button
      class="vox-btn vox-btn-secondary"
      (click)="addItem()"
      [disabled]="maximumNumber"
    >
      <i class="icon-small-add"></i>
    </button>
  </div>
</div>
