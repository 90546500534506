import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FIELD_TYPES, VDValues, VerificationDataItem } from 'ng-voxloud';
import { of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PHONE_NUMBER_CLASSES, PHONE_NUMBER_TYPES, PortabilityNumber, PortabilityRequest } from '../models/portability.model';

@Injectable()
export class AccountNumberService {

  constructor(private http: HttpClient) { }

  public validateVerificationData(vd: VDValues, requirements: VerificationDataItem[]) {
    const formattedVD = this.parseVerificationDataForValidation(vd, requirements);
    return this.http.post(`${environment.legacyEndpoint}/api/v1/verification-data/validate`, formattedVD);
  }

  public parseVerificationDataForValidation(vdValue: VDValues, requirements: VerificationDataItem[]): any {
    let result = {};
    let documents = [];
    requirements.forEach(req => {
      switch (req.field_type) {
        case FIELD_TYPES.FULL_ADDRESS:
          result[req.name] = this.getAddress(vdValue[req.name]);
          break;
        case FIELD_TYPES.DOCUMENT:
          const name = vdValue[req.name].file.name;
          const ext = name.substr(name.lastIndexOf('.'));
          documents.push({
            type: vdValue[req.name].type,
            file_name: `${vdValue[req.name].type}${ext}`
          })
          break;
        default:
          result[req.name] = vdValue[req.name];
      }
    });
    if (documents.length > 0) result['documents'] = documents;
    result['company_id'] = 0; // fake data. Backend requires a company id.
    return result;
  }

  getDocumentsAsArray(vdValue: VDValues, requirements: VerificationDataItem[]) {
    let documents = [];
    requirements.forEach(req => {
      if (req.field_type === FIELD_TYPES.DOCUMENT) {
        const name = vdValue[req.name].file.name;
        const ext = name.substr(name.lastIndexOf('.'));
        documents.push({
          file: vdValue[req.name].file,
          file_name: `${vdValue[req.name].type}${ext}`
        })
      }
    });
    return documents;
  }

  // TODO: remove when the structured data support this object
  public getAddress(gMapsAddress: google.maps.GeocoderAddressComponent[]) {
    return {
      zip_code: this.getZip(gMapsAddress),
      state: this.getCountry(gMapsAddress),
      city: this.getCity(gMapsAddress),
      street_name: this.getStreet(gMapsAddress),
      street_number: this.getBuildingNumber(gMapsAddress),
      building_number: this.getBuildingNumber(gMapsAddress),
    }
  }

  private getCity(gMapsAddress: google.maps.GeocoderAddressComponent[]): string {
    return gMapsAddress.find(
      (el: google.maps.GeocoderAddressComponent) => (
        el.types.includes('locality') ||
        el.types.includes('postal_town') ||
        el.types.includes('sublocality') ||
        el.types.includes('administrative_area_level_3')
      )
    ).short_name;
  }

  private extractAddressComponent(
    address: google.maps.GeocoderAddressComponent[],
    name: string
  ): google.maps.GeocoderAddressComponent {
    return address.find((el) => el.types.includes(name));
  }

  private getCountry(gMapsAddress: google.maps.GeocoderAddressComponent[]): string {
    return this.extractAddressComponent(gMapsAddress, 'country').short_name;
  }

  private getStreet(gMapsAddress: google.maps.GeocoderAddressComponent[]): string {
    return this.extractAddressComponent(gMapsAddress, 'route').short_name;
  }

  private getBuildingNumber(gMapsAddress: google.maps.GeocoderAddressComponent[]): string {
    return this.extractAddressComponent(gMapsAddress, 'street_number').short_name;
  }

  private getZip(gMapsAddress: google.maps.GeocoderAddressComponent[]): string {
    return this.extractAddressComponent(gMapsAddress, 'postal_code').short_name;
  }


  // portability
  public getDocumentToSign(request: PortabilityRequest){
    return this.http.post(`${environment.legacyEndpoint}/api/v1/portabilities/template`, request, {
      responseType: 'blob'
    });
  }

  public getNumbersFromKey(id: string){
    return this.http.get(`${environment.legacyEndpoint}/api/v1/portabilities/numbers/${id}`);
  }
}