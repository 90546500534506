<div class="trustbox-container">
  <!-- TrustBox widget - List -->
  <div
    id="trustbox-list-dark-it"
    class="trustpilot-widget"
    data-locale="it-IT"
    data-template-id="539ad60defb9600b94d7df2c"
    data-businessunit-id="5cb9f717001a030001a8057e"
    data-style-height="800px"
    data-style-width="100%"
    data-theme="dark"
    data-stars="4,5"
  >
    <a
      href="https://it.trustpilot.com/review/voverc.com"
      target="_blank"
      rel="noopener"
      >Trustpilot</a
    >
  </div>
  <!-- End TrustBox widget -->
</div>
