import { Environment } from "./environment.model";

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: Environment = {
  production: false,
  defaultLanguage: "en",
  legacyEndpoint: "https://devapi.voverc.com",
  meetingVoxloudUrl: "https://meeting.voxloud.com/?meeting=g-ditta/parla-col-tuo-specialista&ref=website",
  downloadAppsVoxloudUrl: "https://test-www.voxloud.com/it/apps/",
  zapierPackageWebhook: "https://hooks.zapier.com/hooks/catch/3074487/o4aeu71/",
  gtmpixelCode: "",
  trialPlan: "free-trial-v2",
  intercom_app_id: "ne99i0tn",
  defaultCountryId: "1",
  defaultRegionId: "1",
  supportedLanguages: [
    {
      locale: "it",
      name: "Italiano"
    },
    {
      locale: "en",
      name: "English"
    }
  ],
  reCaptchaKey: '6Ld6B68ZAAAAAPbSCyGdrvX39kMLUXGgA7gPh2m-',
  googlePlacesApiKey: 'AIzaSyButP_itSUxAj98vsXTDCNYKs9Ec58CzPE',
  servedCountries: [
    {
      iso2: 'IT'
    },
    {
      iso2: 'GB',
    },
    {
      iso2: 'US'
    }
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
