import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PrefixModel, VerificationDataResponse } from '../models/info.model';

@Injectable()
export class InfoService {

  constructor(private http: HttpClient) { }

  getPrefixesByCountry(country_code: string) {
    return this.http.get<PrefixModel[]>(`${environment.legacyEndpoint}/api/v1/coverage/countries/${country_code}/areas`);
  }

  getVerificationDataByCountry(country_code: string, language: string) {
    const headers = new HttpHeaders({
      'Accept-language': language
    });
    return this.http.get<VerificationDataResponse>(
      `${environment.legacyEndpoint}/api/v1/verification-data/requirements/${country_code.toUpperCase()}`,
      { headers }
    );
  }
}
