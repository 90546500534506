import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'onb-background-container',
  templateUrl: './background-container.component.html',
  styleUrls: ['./background-container.component.scss']
})
export class BackgroundContainerComponent implements OnInit {
  @Input() direction: string;
  @Input() backgroundColor: string;
  @Input() progressWidth: string;

  constructor() {
  }

  ngOnInit() {}
}
