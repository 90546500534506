import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { PayFacade } from './store/facade';
import { PayService } from './service/pay.service';
import { payReducer } from './store/reducers';

@NgModule({
  imports: [StoreModule.forFeature('pay', payReducer)],
  providers: [PayFacade, PayService]
})
export class PayDomainModule {}
