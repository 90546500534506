import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";

export interface stateModel {
  currentQuantity: number;
  wasOverMax?: boolean;
  wasUnderMin?: boolean;
}

@Component({
  selector: "onb-change-quantity",
  templateUrl: "./change-quantity.component.html",
  styleUrls: ["./change-quantity.component.scss"]
})
export class ChangeQuantityComponent implements OnInit {
  @Input() quantity: number;
  @Input() minQuantity: number;
  @Input() maxQuantity: number;
  stateValidation: stateModel;
  minimumNumber: boolean = false;
  maximumNumber: boolean = false;
  @Output() quantityChanged: EventEmitter<stateModel> = new EventEmitter<
    stateModel
  >();

  timeout;
  timeoutEvent: boolean = false;
  keydownEvent: boolean = false;
  blurEvent: boolean = false;

  constructor() {}

  ngOnInit() {
    this.stateValidation = {
      currentQuantity: this.quantity,
      wasUnderMin: false,
      wasOverMax: false
    };
    this.isMinimum(this.quantity);
    this.isMaximum(this.quantity);
  }

  onPreventDefault(event) {
    switch (event.key) {
      case "+":
      case "-":
        {
          event.preventDefault();
          event.stopPropagation();
        }
        break;
      default:
        break;
    }
  }

  onKeydown(event) {
    this.stopTimeout();
    this.keydownEvent = true;
    this.quantity = event.srcElement.valueAsNumber;
    this.handleInput();
  }

  onInsertNumber(event) {
    event.stopPropagation();
    switch (event.data) {
      case "+":
      case "-":
        {
          event.preventDefault();
        }
        break;
      case "0":
      case "1":
      case "2":
      case "3":
      case "4":
      case "5":
      case "6":
      case "7":
      case "8":
      case "9":
        {
          this.timeout = setTimeout(() => {
            if (this.blurEvent) {
              return;
            }
            this.timeoutEvent = true;
            this.quantity = event.srcElement.valueAsNumber;
            this.handleInput();
            this.stopTimeout();
          }, 2000);
        }
        break;
      default:
        {
          this.timeoutEvent = false;
        }
        break;
    }
  }

  onBlurMethod(event) {
    if (!this.keydownEvent && !this.timeoutEvent) {
      this.blurEvent = true;
      this.stopTimeout();
      this.quantity = event.srcElement.valueAsNumber;
      this.handleInput();
    }
  }

  addItem() {
    this.stateValidation.currentQuantity = this.quantity + 1;
    this.quantityChanged.emit(this.stateValidation);
  }

  deleteItem() {
    this.stateValidation.currentQuantity = this.quantity - 1;
    this.quantityChanged.emit(this.stateValidation);
  }

  isMinimum(quantity: number) {
    if (quantity === this.minQuantity) {
      return (this.minimumNumber = true);
    } else {
      this.minimumNumber = false;
    }
  }

  isMaximum(quantity: number) {
    if (quantity === this.maxQuantity) {
      return (this.maximumNumber = true);
    } else {
      this.maximumNumber = false;
    }
  }

  isEmpty(input) {
    if (isNaN(input)) {
      this.quantity = this.minQuantity;
    }
  }

  validateQuantity(quantity: number) {
    this.isEmpty(quantity);
    if (quantity < this.minQuantity) {
      this.stateValidation.wasUnderMin = true;
      this.stateValidation.wasOverMax = false;
      this.stateValidation.currentQuantity = this.minQuantity;
    } else if (this.maxQuantity !== -1 && quantity > this.maxQuantity) {
      this.stateValidation.wasUnderMin = false;
      this.stateValidation.wasOverMax = true;
      this.stateValidation.currentQuantity = this.maxQuantity;
    } else {
      this.stateValidation.wasUnderMin = false;
      this.stateValidation.wasOverMax = false;
      this.stateValidation.currentQuantity = this.quantity;
    }
  }

  handleInput() {
    this.isMinimum(this.quantity);
    this.isMaximum(this.quantity);
    this.validateQuantity(this.quantity);
    this.quantityChanged.emit(this.stateValidation);
  }

  stopTimeout() {
    clearTimeout(this.timeout);
  }
}
