import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment.prod';
import { ReviewsResponse, ReviewFilters } from 'ng-voxloud';

@Injectable({
  providedIn: 'root'
})
export class ReviewsService {
  constructor(private http: HttpClient,) { }

  getAllReviews(page: number, pageSize: number, filters?: ReviewFilters): Observable<ReviewsResponse> {
    let params = new HttpParams({
      fromObject: {
        page: page.toString(),
        size: pageSize.toString()
      }
    });
    if (filters?.company_id) {
      params = params.append('company_id', filters.company_id);
    }
    if (filters?.is_company_associated) {
      params = params.append('is_company_associated', filters.is_company_associated);
    }
    if (filters?.timestamp_from) {
      params = params.append('timestamp_from', filters.timestamp_from);
    }
    if (filters?.timestamp_to) {
      params = params.append('timestamp_to', filters.timestamp_to);
    }
    if (filters?.platform) {
      params = params.append('platform', filters.platform);
    }
    if (filters?.rating_from) {
      params = params.append('rating_from', filters.rating_from);
    }
    if (filters?.rating_to) {
      params = params.append('rating_to', filters.rating_to);
    }
    return this.http.get<ReviewsResponse>(`${environment.legacyEndpoint}/api/v1/reviews/?sort=timestamp,desc`, { params });
  }
}

export enum PLATFORMS {
  BOTH = 'trustpilot&capterra',
  CAPTERRA = 'capterra'
}