import { Injectable } from '@angular/core';
import { State } from '../reducers';
import { Store, select } from '@ngrx/store';
import {
  selectFullname,
  selectEmail,
  selectPassword,
  selectTerms,
  selectSignupStatus
} from '../selectors/signup.selectors';
import { SaveSignup } from '../actions';
import { User } from '../../model';

@Injectable()
export class SignupFacade {
  fullname$ = this.store.pipe(select(selectFullname));
  email$ = this.store.pipe(select(selectEmail));
  password$ = this.store.pipe(select(selectPassword));
  terms$ = this.store.pipe(select(selectTerms));
  status$ = this.store.pipe(select(selectSignupStatus));

  constructor(private store: Store<State>) {}

  saveSignup(payload: User) {
    this.store.dispatch(new SaveSignup(payload));
  }
}
