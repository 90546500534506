import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SignupState, State } from '../reducers';

export const selectSignupState = createFeatureSelector<SignupState>('signup');

export const selectSignupStatus = createSelector(
  selectSignupState,
  (state: SignupState) => state.signupStatus
);

export const selectFullname = createSelector(
  selectSignupStatus,
  (state: State) => state.fullname
);

export const selectEmail = createSelector(
  selectSignupStatus,
  (state: State) => state.email
);

export const selectPassword = createSelector(
  selectSignupStatus,
  (state: State) => state.password
);

export const selectTerms = createSelector(
  selectSignupStatus,
  (state: State) => state.terms
);
