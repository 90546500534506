import { createFeatureSelector, createSelector } from '@ngrx/store';
import { VerificationDataState } from '../..';


export const selectAccountNumberState = createFeatureSelector<VerificationDataState>('account-number');

export const selectVerificationDataRequirements = createSelector(
  selectAccountNumberState,
  (state) => state.account_number.verificationDataRequirements
);

export const selectHasVerificationDataRequirements = createSelector(
  selectAccountNumberState,
  (state) => state.account_number.hasRequirements
);

export const selectHasVerificationData = createSelector(
  selectAccountNumberState,
  (state) => state.account_number.hasVerificationData
);
export const selectVerificationData = createSelector(
  selectAccountNumberState,
  (state) => state.account_number.verificationData
);

export const selectVerificationDataType = createSelector(
  selectAccountNumberState,
  (state) => state.account_number.type
)

export const selectPortabilityDocuments = createSelector(
  selectAccountNumberState,
  (state) => state.account_number.documents
)

export const selectPortabilityNumbers = createSelector (
  selectAccountNumberState,
  (state) => state.account_number.numbersToPort
)