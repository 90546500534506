import { State, reducer } from './verify-phone.reducer';
import { ActionReducerMap } from '@ngrx/store';

export interface VerifyPhoneState {
  status: State;
}

export const verifyPhoneReducer: ActionReducerMap<VerifyPhoneState> = {
  status: reducer
};

export * from './verify-phone.reducer';
