import { Action } from '@ngrx/store';

export enum VerifyPhoneActionsTypes {
  DropNumber = '[Verify Phone] Drop Number',
  SendNumber = '[Verify Phone] Send Number',
  SaveNumber = '[Verify Phone] Save Number',
  ResendNumber = '[Verify Phone] Resend Number',
  ResendNumberSuccess = '[Verify Phone] Resend Number Success',
  SendCode = '[Verify Phone] Send Code',
  SendNumberSuccess = '[Verify Phone] Send Number Success',
  SendNumberFailure = '[Verify Phone] Send Number Failure',
  SendCodeSuccess = '[Verify Phone] Send Code Success',
  SendCodeFailure = '[Verify Phone] Seond Code Failure'
}

export class DropNumber implements Action {
  readonly type = VerifyPhoneActionsTypes.DropNumber;
}

export class SendNumber implements Action {
  readonly type = VerifyPhoneActionsTypes.SendNumber;
  constructor(readonly payload: string) {}
}

export class SaveNumber implements Action {
  readonly type = VerifyPhoneActionsTypes.SaveNumber;
  constructor(readonly payload: string) {}
}

export class ResendNumber implements Action {
  readonly type = VerifyPhoneActionsTypes.ResendNumber;
  constructor(readonly payload: string) {}
}

export class ResendNumberSuccess implements Action {
  readonly type = VerifyPhoneActionsTypes.ResendNumberSuccess;
  constructor(readonly payload: string) {}
}

export class SendNumberSuccess implements Action {
  readonly type = VerifyPhoneActionsTypes.SendNumberSuccess;
  constructor(readonly payload: string) {}
}

export class SendNumberFailure implements Action {
  readonly type = VerifyPhoneActionsTypes.SendNumberFailure;
  constructor(readonly payload: string) {}
}

export class SendCode implements Action {
  readonly type = VerifyPhoneActionsTypes.SendCode;
  constructor(readonly payload: { code: string; token: string }) {}
}

export class SendCodeSuccess implements Action {
  readonly type = VerifyPhoneActionsTypes.SendCodeSuccess;
}

export class SendCodeFailure implements Action {
  readonly type = VerifyPhoneActionsTypes.SendCodeFailure;
  constructor(readonly payload: string) {}
}

export type VerifyPhoneActions =
  | DropNumber
  | SendNumber
  | SaveNumber
  | SendCode
  | ResendNumber
  | ResendNumberSuccess
  | SendNumberSuccess
  | SendNumberFailure
  | SendCodeSuccess
  | SendCodeFailure;
