import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import {
  VerifyPhoneActionsTypes,
  SendNumber,
  SendNumberSuccess,
  SendNumberFailure,
  SendCode,
  SendCodeSuccess,
  SendCodeFailure,
  ResendNumber,
  ResendNumberSuccess
} from '../actions';
import { VerifyPhoneService } from '../../services';
import { map, switchMap, catchError, tap, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { Logger } from 'src/app/core/logger';
import { AppFacade, IncrementStep } from 'src/app/domains/app/store';
import { error } from 'protractor';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class VerifyPhoneEffects {
  private log = new Logger('Verify Phone Effects');

  @Effect()
  sendNumberEffect = this.actions$.pipe(
    ofType(VerifyPhoneActionsTypes.SendNumber),
    map((action: SendNumber) => action.payload),
    switchMap((number: string) =>
      this.service.sendNumber(number).pipe(
        map((res: { token: string }) => new SendNumberSuccess(res.token)),
        catchError(error => {
          if(error.status === 400) {
          return of(new SendCodeSuccess());
        } return of(new SendNumberFailure(error))})
      )
    )
  );

  @Effect({ dispatch: false })
  redirectToSms = this.actions$.pipe(
    ofType(VerifyPhoneActionsTypes.SendNumberSuccess),
    tap(() => this.appFacade.incrementStep())
  );

  @Effect()
  ResendNumber = this.actions$.pipe(
    ofType(VerifyPhoneActionsTypes.ResendNumber),
    map((action: ResendNumber) => action.payload),
    switchMap((number: string) =>
      this.service.sendNumber(number).pipe(
        map((res: { token: string }) => new ResendNumberSuccess(res.token)),
        catchError(error => of(new SendNumberFailure(error)))
      )
    )
  );

  @Effect()
  sendCode = this.actions$.pipe(
    ofType(VerifyPhoneActionsTypes.SendCode),
    map((action: SendCode) => action.payload),
    switchMap((value: { code: string; token: string }) =>
      this.service.verifyCode(value.code, value.token).pipe(
        map(() => new SendCodeSuccess()),
        catchError(error => of(new SendCodeFailure(error)))
      )
    )
  );

  @Effect()
  redirectToNext = this.actions$.pipe(
    ofType(VerifyPhoneActionsTypes.SendCodeSuccess),
    switchMap(token => of(new IncrementStep()))
  );

  constructor(
    private actions$: Actions,
    private service: VerifyPhoneService,
    private appFacade: AppFacade,
    private router: Router
  ) {}
}
