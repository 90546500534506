import { State, reducer } from './choose-plan.reducers';
import { ActionReducerMap } from '@ngrx/store';

export * from './choose-plan.reducers';

export interface ChoosePlanState {
  status: State;
}

export const choosePlanReducer: ActionReducerMap<ChoosePlanState> = {
  status: reducer
};
