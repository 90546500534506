import { Injectable } from "@angular/core";

import { Store, select } from "@ngrx/store";

import { StarterKitState } from "../reducers";
import {
  selectStarterKitID,
  selectStarterKitName,
  selectStarterKitDescription,
  selectStarterKitURL,
  selectStarterKitStatus,
  selectStarterKitEquipments,
  selectStarterKitAddonId
} from "../selectors";
import { StoreStarterKit, RemoveStarterKit, StoreStarterKitEquipments } from "../actions";
import { Equipment } from '../../models';


@Injectable()
export class StarterKitFacade {
  constructor(private store: Store<StarterKitState>) { }
  
  id$ = this.store.pipe(select(selectStarterKitID));
  addon_id$ = this.store.pipe(select(selectStarterKitAddonId));
  name$ = this.store.pipe(select(selectStarterKitName));
  description$ = this.store.pipe(select(selectStarterKitDescription));
  infoURL$ = this.store.pipe(select(selectStarterKitURL));
  equipments$ = this.store.pipe(select(selectStarterKitEquipments));
  starterKit$ = this.store.pipe(select(selectStarterKitStatus));

  

  storeStarterKit(starterKitID: string, addon_id: string) {
    this.store.dispatch(new StoreStarterKit(starterKitID, addon_id));
  }

  removeStarterKit(starterKitID: string, addon_id: string) {
    this.store.dispatch(new RemoveStarterKit(starterKitID, addon_id));
  }

  storeStarterKitEquipments(equipments: Equipment[]) {
    this.store.dispatch(new StoreStarterKitEquipments(equipments));
  }
}