import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { AppFacade } from "../../app/store";
import { switchMap, take } from "rxjs/operators";
import { TokenResponse } from "../../auth/models/auth.model";
import { Observable, throwError } from "rxjs";
import { KazooInstanceResponse, PlanDto } from "../models";

@Injectable()
export class ChoosePlanService {
  constructor(
    private http: HttpClient,
    private app: AppFacade
  ) {}

  private createHeaders(token:TokenResponse) {
    const headers = new HttpHeaders({
      'voverc-jwt-auth': token.jwt
    });
    return { headers };
  }

  getPlans(is_internal:boolean):Observable<PlanDto[]> {
    const param = is_internal ? '?is_internal=true': '';
    return this.app.apiToken$.pipe(
      take(1),
      switchMap((token: TokenResponse)=> {
        if (token?.jwt) {
          return this.http.get<PlanDto[]>(
            `${environment.legacyEndpoint}/api/v1/subscriptions/plans${param}`,
            this.createHeaders(token)
          );
        } else {
          return throwError([]);
        }
      })
    )
  }

  getKazooInstances(): Observable<KazooInstanceResponse> {
    return this.app.apiToken$.pipe(
      take(1),
      switchMap((token: TokenResponse)=> {
        if (token?.jwt) {
          return this.http.get<KazooInstanceResponse>(
            `${environment.legacyEndpoint}/api/v1/pbxes/kazoo`,
            this.createHeaders(token)
          );
        } else {
          return throwError(null);
        }
      })
    )
  }
}
