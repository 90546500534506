import { createFeatureSelector, createSelector } from "@ngrx/store";
import { selectPartnerID } from "src/app/domains/app/store";
import { PlanDto } from "src/app/domains/choose-plan/models";
import { InfoState } from "src/app/domains/info/store/reducers";
import { selectInfoState } from "src/app/domains/info/store/selectors";
import { SignupState, State } from "src/app/domains/signup/store/reducers";
import { selectSignupStatus } from "src/app/domains/signup/store/selectors/signup.selectors";
import { selectPhoneNumber } from "src/app/domains/verify-phone/store/selectors";
import { CoverageStoreState } from "../reducers";

export const selectCoverageState = createFeatureSelector<CoverageStoreState>('coverage');

export const selectCoverageData = createSelector(
    selectCoverageState,
    (state: CoverageStoreState) => state.coverageStore.coverageData
);

export const getNecessaryData = createSelector(
    selectSignupStatus,
    selectInfoState,
    selectPhoneNumber,
    selectPartnerID,
    (signup: State, info: InfoState, phoneNumber: string, partnerid: string) => {
        return {
            signupData: signup,
            infoData: info,
            phoneData: phoneNumber,
            partnerId: partnerid
        }
    }
)