import { Logger } from '../../../../core/logger';
import { Injectable } from '@angular/core';
import { Error } from './error.model';
import { Subject } from 'rxjs';

const log = new Logger('Error Service');

@Injectable({ providedIn: 'root' })
export class ErrorService {
  private _lastError: Error;
  private _error$ = new Subject<Error>();

  set lastError(error: Error) {
    this._lastError = error;
  }

  get lastError(): Error {
    return this._lastError;
  }

  set error$(error: Subject<Error>) {
    this._error$ = error;
  }

  get error$(): Subject<Error> {
    return this._error$;
  }

  throwError(error: Error) {
    log.info('Error thrown');
    this.lastError = error;
    this.error$.next(error);
  }
}
