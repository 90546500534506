import { Action } from '@ngrx/store';
import { State } from '../reducers/info.reducers';
import { InfoPayloadModel } from '../../models/info.model';

export enum InfoActionsTypes {
  SaveInfo = '[Info] Save Info no Shipping',
  SaveInfoShipping = '[Info] Save Info with Shipping',
  SaveVerificationDataRequirements = '[Info] Save Verification Data Requirements'
}

export class SaveInfo implements Action {
  readonly type = InfoActionsTypes.SaveInfo;
  constructor(readonly payload: InfoPayloadModel) {}
}

export class SaveInfoShipping implements Action {
  readonly type = InfoActionsTypes.SaveInfoShipping;
  constructor(readonly payload: InfoPayloadModel) {}
}

export type InfoActions = SaveInfo | SaveInfoShipping;
