import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { verifyPhoneReducer } from "./store/reducers";
import { EffectsModule } from "@ngrx/effects";
import { VerifyPhoneEffects } from "./store/effects";
import { VerifyPhoneService } from "./services";
import { VerifyPhoneFacade } from "./store/facades";

@NgModule({
  imports: [
    StoreModule.forFeature("verifyPhone", verifyPhoneReducer),
    EffectsModule.forFeature([VerifyPhoneEffects])
  ],
  providers: [VerifyPhoneService, VerifyPhoneFacade]
})
export class VerifyPhoneDomainModule {}
