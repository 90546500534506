import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { CoverageService } from "./service/coverage.service";
import { CoverageFacade } from "./store/facade";
import { coverageReducer } from "./store/reducers";

@NgModule({
    imports: [StoreModule.forFeature('coverage', coverageReducer)],
    providers: [CoverageFacade, CoverageService]
})
export class CoverageDomainModule{}