import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { addonReducer } from "./store/reducers";
import { AddonFacade } from "./store/facade";
import { AddonService } from "./services";

@NgModule({
  imports: [StoreModule.forFeature("addon", addonReducer)],
  providers: [AddonFacade, AddonService]
})
export class AddonDomainModule {}
