import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';

@Injectable()
export class VerifyPhoneService {
  private SendCodeApi = `${environment.legacyEndpoint}/api/v1/sms-verification/send`;
  private VerifyCodeApi = `${environment.legacyEndpoint}/api/v1/sms-verification/verify`;

  constructor(private http: HttpClient) {}

  sendNumber(number: string) {
    return this.http.post(this.SendCodeApi, { number: number });
  }

  verifyCode(code: string, token: string) {
    return this.http.post(this.VerifyCodeApi, {
      code: code,
      token: token
    });
  }

  checkPhoneNumberExists(phone: string) {
    return this.http.post(
      `${environment.legacyEndpoint}/api/v2/onboarding/check/phone`,
      { phone: phone },
      { observe: "response" }
    );
  }

  checkPhoneNumberExistsOnPorting(phone: string): Observable<{is_present: boolean}>{
    return this.http.get<{is_present: boolean}>(`${environment.legacyEndpoint}/api/v1/dids/by-number/${phone}/presence`)
  }
}