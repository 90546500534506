import { FlowConfig } from './flow.model';

export const flowStepsConfigInternals: FlowConfig = {
  wildix_internals: {
    steps: [
      "signup",
      "verify-phone",
      "info",
      "choose-plan0",
      "activation"
    ],
    data: {
      auth: true,
      askPaymentMethod: false,
      initialCountry: 'IT',
      sendSms: false,
      redirectToUrl: "attiva_ora",
      internalPlan: 'free-wildix-internals',
      servedCountries: [
        {
          iso2: 'IT'
        }
      ]
    }
  }
}
