import { WifiActivationRequest } from "../../models/coverage.model";
import { CoverageActions, CoverageActionsTypes } from "../actions/coverage.actions";

export interface CoverageState {
    coverageData: WifiActivationRequest;
};

export const coverageInitialState: CoverageState = {
    coverageData: null
};

export function reducer(state = coverageInitialState, action: CoverageActions): CoverageState {
    switch(action.type){
        case CoverageActionsTypes.SaveCoverageData:
            return {
                ...state,
                coverageData: action.payload
            };
        default: 
            return state;
    }
}