import { NgModule } from "@angular/core";

import { StoreModule } from "@ngrx/store";

import { starterKitReducer } from "./store/reducers";
import { StarterKitFacade } from "./store/facade";
import { StarterKitService } from './services';

@NgModule({
  imports: [StoreModule.forFeature("starter-kit", starterKitReducer)],
  providers: [StarterKitFacade, StarterKitService]
})
export class StarterKitDomainModule {}
