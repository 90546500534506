import { Component, OnInit, Input } from "@angular/core";
import { I18nService } from "src/app/core/i18n";
import { Languages } from "./languages.model";
import { environment } from "src/environments/environment";
import { AppFacade } from 'src/app/domains/app/store';
import { take } from 'rxjs/operators';
import { LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: "onb-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
  langs: Languages[];
  currentLang: string;
  _phoneCountry: string = 'gb';
  @Input() colorClass: string = "onb-header--color-black";
  @Input() blackLogo: boolean = true;
  @Input() set phoneCountry(v: string) {
    if (v) {
      let phone = this.phoneNumbers.find(el => el.country === v.toLowerCase());
      if (phone) {
        this.selectedPhoneNumber = phone;
        this._phoneCountry = v.toLowerCase();
      } else {
        this.fallbackToInitialCountry();
      }
    }
  }
  desktopLogo: string;
  mobileLogo: string;
  currentWidth: string;
  public phoneNumbers: Array<any> =
  [
    {
      country: 'it',
      flagClass: 'flag-icon-it',
      number: '+39 06 97631527'
    },
    {
      country: 'gb',
      flagClass: 'flag-icon-gb',
      number: '+44 20 37691920'
    },
    {
      country: 'us',
      flagClass: 'flag-icon-us',
      number: '+1 917 9098338'
    }
  ];
  public selectedPhoneNumber: any;

  constructor(private i18n: I18nService, private appFacade: AppFacade) { }

  ngOnInit() {
    this.currentLang = this.i18n.language;
    this.i18n.languageChange.subscribe((evt: LangChangeEvent) => {
      if (evt.lang !== this.currentLang) {
        this.currentLang = evt.lang;
      }
    });
    this.langs = environment.supportedLanguages;
    if (this.blackLogo) {
      this.desktopLogo = "../../../../assets/img/logo/voxloud-logo.svg";
      this.mobileLogo = "../../../../assets/img/logo/voxloud-wordmark.svg";
    } else {
      this.desktopLogo = "../../../../assets/img/logo/voxloud-logo_reverse.svg";
      this.mobileLogo = "../../../../assets/img/logo/voxloud-wordmark.svg";
    }

    this.currentWidth = window.innerWidth > 992 ? "right" : "left";
    window.onresize = () => {
      this.currentWidth = window.innerWidth > 992 ? "right" : "left";
    };
    if (!this.selectedPhoneNumber) {
      this.selectedPhoneNumber = this.phoneNumbers.find(el => el.country === this._phoneCountry);
    }
  }

  onChange(evt) {
    this.i18n.language = evt;
  }

  onSelectPhoneNumber(phoneNumber: string) {
    this.selectedPhoneNumber = phoneNumber;
  }

  fallbackToInitialCountry() {
    //extremis fallback in case what is passed to the component is not allowd
    this.appFacade.data$.pipe(take(1)).subscribe(data => {
      this._phoneCountry = data.initialCountry.toLowerCase();
      if (!this.selectedPhoneNumber) {
        this.selectedPhoneNumber = this.phoneNumbers.find(el => el.country === this._phoneCountry);
      }
    });
  }
}
