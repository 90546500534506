import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CoverageRequest, CoverageResponse, FallbackAddressesRequest, FallbackAddressesResponse, FallbackBuildingsRequest, FallbackBuildingsResponse, FallbackCitiesRequest, FallbackCitiesResponse } from "../models/coverage.model";

@Injectable()
export class CoverageService{

    constructor(private http: HttpClient){}

    public getCoverageResults(request: CoverageRequest): Observable<CoverageResponse> {
        return this.http.post<CoverageResponse>(`${environment.legacyEndpoint}/api/v1/coverage/fiber`, request);
    }

    public getFallbackCity(request: FallbackCitiesRequest){
        const params = new HttpParams().append('city', request.city);
        return this.http.get<FallbackCitiesResponse[]>(
        `${environment.legacyEndpoint}/api/v1/coverage/fiber/countries/${request.country}/cities`,
        {
            params: params
        });
    }

    public getFallbackStreet(request: FallbackAddressesRequest){
        const params = new HttpParams().appendAll({
            'city_id': request.cityId,
            'street': request.street
        });
        return this.http.get<FallbackAddressesResponse[]>(
        `${environment.legacyEndpoint}/api/v1/coverage/fiber/countries/${request.country}/addresses`,
        {
            params: params
        });
    }

    public getFallbackBuilding(request: FallbackBuildingsRequest){
        const params = new HttpParams().appendAll({
            'address_id': request.streetId,
            'building_number': request.buildingNumber
        });
        return this.http.get<FallbackBuildingsResponse[]>(
        `${environment.legacyEndpoint}/api/v1/coverage/fiber/countries/${request.country}/buildings`,
        {
            params: params
        });
    }
}