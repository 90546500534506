import { Injectable } from '@angular/core';
import { InfoState, State } from '../reducers';
import { Store, select } from '@ngrx/store';
import { selectInfoStatus } from '../selectors';
import { SaveInfo, SaveInfoShipping } from '../actions';
import { InfoPayloadModel } from '../../models/info.model';

@Injectable()
export class InfoFacade {
  constructor(private store: Store<InfoState>) {}
  
  infoStatus$ = this.store.pipe(select(selectInfoStatus));

  

  saveInfo(info: InfoPayloadModel) {
    if (info.correspond) this.store.dispatch(new SaveInfo(info));
    else this.store.dispatch(new SaveInfoShipping(info));
  }
}
