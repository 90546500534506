<div
  [ngClass]="{ 'user-slider-container': true, 'user-slider-container--smaller': ranges.length === 2 }">
  <input class="slider"
      name="slider"
      type="range"
      min="0"
      [step]="STEP"
      [max]="MAX_VALUE"
      [value]="sliderValue"
      (input)="onChange($event)"
      #slider>
  <div (click)="clickFill($event)" [ngStyle]="{width: fillWidth}" class="slider-fill"></div>
  <output  for="slider"
    *ngIf="discountPercentage > 0 && showTooltip"
    [ngStyle]="{ left : tooltipLeftPosition }">
    -{{discountPercentage}}%
  </output>
  <div class="count-ranges">
    <span *ngFor="let r of ranges; let i = index;"
        [ngClass]="{ 'active' : discountPercentage === r.discount }"
        (click)="setRange(i)">
      {{r.label}}
    </span>
  </div>
</div>
