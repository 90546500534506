import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { ErrorService } from "../service/error.service";
import { Error, ERRORTYPES } from "../service/error.model";
import { Logger } from "../../../../core/logger";
import { IntercomChatService } from "../../../../core/intercom/services/intercom-chat.service";

const log = new Logger("Error component");

@Component({
  selector: "onb-error",
  templateUrl: "./error.component.html",
  styleUrls: ["./error.component.scss"]
})
export class ErrorComponent implements OnInit, OnDestroy {
  private errorSubscription: Subscription;
  public error: Error = {
    type: 0,
    message: ""
  };
  public classes = {};
  public shown = false;

  constructor(
    private errorService: ErrorService,
    public chatService: IntercomChatService
  ) {}

  close() {
    if (this.error.options && !this.error.options.instant) {
      this.executeCallback();
    }
    this.shown = false;
    this.classes["vox-inline-message_in"] = this.shown;
    this.classes["vox-inline-message_out"] = !this.shown;
  }

  executeCallback() {
    if (this.error.callback) {
      const args =
        this.error.options && this.error.options.callbackArgs.splice(1);
      if (args) {
        this.error.callback.apply(this.error.options.callbackArgs[0], args);
      } else {
        this.error.callback();
      }
    }
  }

  ngOnInit() {
    this.errorSubscription = this.errorService.error$.subscribe(
      (error: Error) => {
        this.error.type = error.type;
        this.error.message = error.message;
        this.error.callback = error.callback;
        this.error.options = error.options;
        this.shown = true;
        this.classes = {
          "vox-inline-message_bright-crimson":
            error.type === ERRORTYPES.CRITICAL,
          "vox-inline-message_royal-blue": error.type === ERRORTYPES.INFO,
          "vox-inline-message_gold": error.type === ERRORTYPES.WARNING,
          "vox-inline-message_aquamarine": error.type === ERRORTYPES.SUCCESS,
          "vox-inline-message_in": this.shown,
          "vox-inline-message_out": !this.shown
        };
        // when is CRITICAL intercom chat is shown
        if (error.type === 3) {
          this.chatService.show();
        }

        log.info(error.options);
        if (this.error.options && this.error.options.instant) {
          this.executeCallback();
        }
        if (this.error.options && this.error.options.delay) {
          const timeout = setTimeout(() => {
            this.close();
          }, this.error.options.delay);
        }
      }
    );
  }

  ngOnDestroy() {
    this.errorSubscription.unsubscribe();
  }
}
