import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { AccountNumberFacade, verificationDataReducer, AccountNumberService } from '.';



@NgModule({
  declarations: [],
  providers: [AccountNumberFacade, AccountNumberService],
  imports: [StoreModule.forFeature("account-number", verificationDataReducer)]
})
export class AccountNumberDomainModule { }
