import { Injectable } from '@angular/core';
import { State } from '../reducers';
import { Store, select } from '@ngrx/store';
import * as fromSelectors from '../selectors';
import { SendNumber, SendCode, ResendNumber, SaveNumber } from '../actions';

@Injectable()
export class VerifyPhoneFacade {
  constructor(private store: Store<State>) {}

  number$ = this.store.pipe(select(fromSelectors.selectPhoneNumber));
  code$ = this.store.pipe(select(fromSelectors.selectCode));
  token$ = this.store.pipe(select(fromSelectors.selectToken));
  loading$ = this.store.pipe(select(fromSelectors.selectLoading));
  error$ = this.store.pipe(select(fromSelectors.selectError));


  sendNumber(payload: string) {
    this.store.dispatch(new SendNumber(payload));
  }

  saveNumber(payload: string) {
    this.store.dispatch(new SaveNumber(payload));
  }

  sendCode(payload: { code: string; token: string }) {
    this.store.dispatch(new SendCode(payload));
  }

  resendCode(payload: string) {
    this.store.dispatch(new ResendNumber(payload));
  }
}
