import { createFeatureSelector, createSelector } from "@ngrx/store";
import { State, AddonState } from "../reducers";
import { Addons } from "../../model";

export const selectAddonStatus = createFeatureSelector<AddonState>("addon");

export const selectStatus = createSelector(
  selectAddonStatus,
  (state: AddonState) => state.status
);

export const selectAddons = createSelector(
  selectStatus,
  (state: State) => state.addons
);

export const getAddons = createSelector(selectAddons, (addons: Addons) => {
  let ads = {};
  if (Object.keys(addons).length > 0) {
    Object.keys(addons).forEach(e => {
      ads[addons[e].name] = addons[e].quantity;
    });
  }
  return Object.keys(ads).length > 0 ? ads : undefined;
});
