import { Component, OnInit } from "@angular/core";

declare global {
  interface Window {
    Trustpilot: any;
  }
}

@Component({
  selector: "onb-trustbox-list-dark-it",
  templateUrl: "./trustbox-list-dark-it.component.html",
  styleUrls: ["./trustbox-list-dark-it.component.scss"]
})
export class TrustboxListDarkItComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    const trustboxRefIT = document.getElementById("trustbox-list-dark-it");
    window.Trustpilot.loadFromElement(trustboxRefIT);
  }
}
