<div class="modal-body referrer-modal">
  <div class="content">
      <div class="content__top">
          <h4 translate>signup.modals.referrer.title</h4>
          <button type="button" class="close" aria-label="Close" (click)="close()">
            <span aria-hidden="true"><i class="icon-small-close"></i></span>
          </button>
      </div>
      <div class="content__body">
        <h5 class="referrer-modal__headline vox-heading-4" translate>signup.modals.referrer.content.headline</h5>
        <div class="referrer-modal__illustration">
          <img [src]="'signup.modals.referrer.content.illustration' | translate" alt="Top illustration">
        </div>
        <button (click)="close()" class="referrer-modal__button vox-btn vox-btn-primary" translate>signup.modals.referrer.content.button</button>
      </div>
  </div>
</div>
