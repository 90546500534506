import { Action } from "@ngrx/store";
import { TokenResponse } from 'src/app/domains/auth/models/auth.model';

export enum AppActionTypes {
  SetLocale = "[App] Set Locale",
  IncrementStep = "[App] Increment Step",
  DecrementStep = "[App] Decrement Step",
  SilentIncrementStep = "[App] Silent Increment Step",
  SilentDecrementStep = "[App] Silent Decrement Step",
  JumpToFirstStep = "[App] Jump To First Step",
  UpdateStepProgress = "[App] Update Step Progress",
  SetStep = "[App] Set Step",
  SetIsToken = "[App] Set Is Token",
  SetStart = "[App] Set Start",
  SetDealID = "[App Role] Set Deal ID",
  SetReferrerID = "[App Role] Set Referrer ID",
  SetPartnerID = "[App Role] Set Partner ID",
  SetAgentID = "[App Role] Set Agent ID",
  SetInitialCountry = "[App Query Param] Set Initial Country",
  SetKazooInstanceId = "[App Query Param] Set Kazoo Instance Id",
  SetChargebeeAccountId = "[App Query Param] Set Chargebee Account Id",
  SkipStarterKits = "[App] Skip Starter Kits",
  SkipNextNSteps = "[App] Skip Next N Steps",
  SetAvailablePaymentMethods = "[App] Set Payment Methods Availability",
  SetApiToken = "[App] Set Api Token"
}

export class SetLocale implements Action {
  readonly type = AppActionTypes.SetLocale;
  constructor(readonly payload: string) {}
}

export class IncrementStep implements Action {
  readonly type = AppActionTypes.IncrementStep;
}

// TODO: Remove when we don't have to hide stater kits
export class SkipStarterKits implements Action {
  readonly type = AppActionTypes.SkipStarterKits;
}

export class SkipNextNSteps implements Action {
  readonly type = AppActionTypes.SkipNextNSteps;
  constructor(readonly payload: number) { }
}

export class DecrementStep implements Action {
  readonly type = AppActionTypes.DecrementStep;
}

export class SilentIncrementStep implements Action {
  readonly type = AppActionTypes.SilentIncrementStep;
}

export class SilentDecrementStep implements Action {
  readonly type = AppActionTypes.SilentDecrementStep;
}

export class JumpToFirstStep implements Action {
  readonly type = AppActionTypes.JumpToFirstStep;
}

export class UpdateStepProgress implements Action {
  readonly type = AppActionTypes.UpdateStepProgress;
  constructor(readonly payload: string) {}
}

/**
 * This action is used by the AppService to put a step without queryParams
 */
export class SetStep implements Action {
  readonly type = AppActionTypes.SetStep;
  constructor(readonly payload: string) {}
}

export class SetIsToken implements Action {
  readonly type = AppActionTypes.SetIsToken;
  constructor(readonly isToken: boolean) {}
}

export class SetStart implements Action {
  readonly type = AppActionTypes.SetStart;
  constructor(readonly started: boolean) {}
}

export class SetDealID implements Action {
  readonly type = AppActionTypes.SetDealID;
  constructor(readonly dealID: string) {}
}

export class SetReferrerID implements Action {
  readonly type = AppActionTypes.SetReferrerID;
  constructor(readonly referrerID: string) { }
}

export class SetPartnerlID implements Action {
  readonly type = AppActionTypes.SetPartnerID;
  constructor(readonly partnerID: string) {}
}

export class SetAgentID implements Action {
  readonly type = AppActionTypes.SetAgentID;
  constructor(readonly agentID: string) {}
}

export class SetInitialCountry implements Action {
  readonly type = AppActionTypes.SetInitialCountry;
  constructor(readonly country: string) { }
}

export class SetKazooInstanceId implements Action {
  readonly type = AppActionTypes.SetKazooInstanceId;
  constructor(readonly kazooInstanceId: string) { }
}

export class SetChargebeeAccountId implements Action {
  readonly type = AppActionTypes.SetChargebeeAccountId;
  constructor(readonly chargebeeAccountId: string) { }
}

export class SetAvailablePaymentMethods implements Action {
  readonly type = AppActionTypes.SetAvailablePaymentMethods;
  constructor(readonly payment_methods_available: string[]) { }
}

export class SetApiToken implements Action {
  readonly type = AppActionTypes.SetApiToken;
  constructor(readonly api_token: TokenResponse) { }
}

export type AppAction =
  | SetLocale
  | IncrementStep
  | DecrementStep
  | SilentIncrementStep
  | SilentDecrementStep
  | JumpToFirstStep
  | UpdateStepProgress
  | SkipStarterKits
  | SkipNextNSteps
  | SetStep
  | SetIsToken
  | SetStart
  | SetDealID
  | SetReferrerID
  | SetPartnerlID
  | SetAgentID
  | SetInitialCountry
  | SetKazooInstanceId
  | SetChargebeeAccountId
  | SetAvailablePaymentMethods
  | SetApiToken;
