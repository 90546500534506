import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
  ElementRef,
  Input
} from '@angular/core';

interface UserRange {
  from: number;
  to: number;
};

export interface Range {
  discount: number;
  label: string;
  users: UserRange
};

const MAX_VALUE = 1;
// constants below has to be in sync with styles
const HANDLE_SIZE = 20;
const TOOLTIP_SIZE = 50;
const SLIDER_HEIGHT = 4;

@Component({
  selector: 'user-count-slider',
  templateUrl: './user-count-slider.component.html',
  styleUrls:['./user-count-slider.component.scss']
})
export class UserCountSliderComponent implements OnInit{
  @Input('showTooltip') showTooltip: boolean;
  @Input('ranges') ranges: Range[];
  @Output() userRangeChange: EventEmitter<Range> = new EventEmitter();
  @ViewChild('slider') slider: ElementRef;
  public sliderValue: number;
  public STEP = 0;
  public readonly MAX_VALUE = MAX_VALUE


  constructor() {}

  private valueToIndex(value: number):number {
    const v = Number(value);
    return this.getRangeIndex(value);
  }

  private getRangeIndex(value: number): number {
    const index = Math.floor(
      this.ranges.length * value / MAX_VALUE
    );
    // avoid returning index beyond last element
    return index === this.ranges.length
      ? this.ranges.length -1
      : index;
  }

  ngOnInit() {
    this.STEP = MAX_VALUE / (this.ranges.length - 1)
    this.sliderValue = this.STEP;
    const index = this.getRangeIndex(this.sliderValue)
    this.userRangeChange.emit(this.ranges[index]);
  }

  get discountPercentage():number {
    const range = this.ranges[this.getRangeIndex(this.sliderValue)];
    // cutoff fractional part if present
    return range ? (range.discount - (range.discount % 1)) : 0;
  }

  get tooltipLeftPosition() {
    const newValue = this.sliderValue / MAX_VALUE;
    return `calc(${100*newValue}% - ${0.5*TOOLTIP_SIZE}px + ${(0.5 - newValue)*HANDLE_SIZE}px )`;
  }

  get fillWidth() {
    const newValue = this.sliderValue / MAX_VALUE;
    // correction makes sure that fill is not touching handle,
    // but fill corners are on handle border, overlapping handle a bit
    const correction = HANDLE_SIZE*( 1 - Math.sqrt( 1 - Math.pow(SLIDER_HEIGHT,2)/( 4*Math.pow(HANDLE_SIZE,2) ) ));
    return `calc(${100*(newValue)}% - ${(newValue - correction)*HANDLE_SIZE}px )`;
  }

  public setRange(index: number) {
    this.onChange({
      target: {
        value: index * MAX_VALUE / (this.ranges.length -1)
      }
    });
  }

  public clickFill(event: MouseEvent) {
    const width: number = this.slider.nativeElement.offsetWidth;
    const index = this.valueToIndex(MAX_VALUE * event.offsetX / width);
    this.setRange(index);
  }

  public onChange(event: Event | any): void {
    const index = this.valueToIndex(event.target.value);
    this.sliderValue = event.target.value;
    this.userRangeChange.emit(
      this.ranges[index]
    );
  }
}
