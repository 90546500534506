import { createFeatureSelector, createSelector } from "@ngrx/store";
import { StarterKitState } from "../reducers";
import { State } from "../reducers/starter-kit-reducer";

export const selectStarterKitState = createFeatureSelector<StarterKitState>(
  "starter-kit"
);

export const selectStarterKitStatus = createSelector(
  selectStarterKitState,
  (state: StarterKitState) => state.status
);

export const selectStarterKitID = createSelector(
  selectStarterKitStatus,
  (state: State) => state.id
);

export const selectStarterKitName = createSelector(
  selectStarterKitStatus,
  (state: State) => state.name
);

export const selectStarterKitDescription = createSelector(
  selectStarterKitStatus,
  (state: State) => state.description
);

export const selectStarterKitURL = createSelector(
  selectStarterKitStatus,
  (state: State) => state.infoURL
);

export const selectStarterKitAddonId = createSelector(
  selectStarterKitStatus,
  (state: State) => state.addon_id
);

export const selectStarterKitEquipments = createSelector(
  selectStarterKitStatus,
  (state: State) => state.equipments
);
