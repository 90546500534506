export interface Error {
  message: string;
  type: ERRORTYPES;
  callback?: (...args: any[]) => void; // Callback to call after the error is shown (for example to redirect)
  options?: ErrorOption; // Options for the callback
}

export interface ErrorOption {
  instant?: boolean; // Option to call the callback instantly. Default behaviour is to call it after che message is closed
  delay?: number; // Delay before closing in ms
  callbackArgs?: Array<any>; // Callback args. If callback is not setted they will be ignored. The first argument is always user as the context (this).
}

export enum ERRORTYPES {
  CRITICAL = 3,
  WARNING = 2,
  SUCCESS = 1,
  INFO = 0
}
