import { InfoActions, InfoActionsTypes } from "../actions/info.actions";
import { AddressComponent, AddressComponents } from "../../models/info.model";
import { StructuredAddressData } from 'ng-voxloud';

export interface State {
  business_name: string;
  vat: { country: string, vat: string };
  invoicing_email: string[];
  correspond: boolean;
  shipping_name: string;
  apartment_number: string;
  city_id: number;
  city_name: string;
  billing_address: AddressComponents;
  shipping_address: AddressComponents;
  prefix: string;
  country: string;
}

export const initialState: State = {
  business_name: "",
  vat: null,
  invoicing_email: [""],
  correspond: true,
  apartment_number: "",
  shipping_name: "",
  city_id: 0,
  city_name: "",
  billing_address: null,
  shipping_address: null,
  prefix: null,
  country: null
};

export function reducer(state = initialState, action: InfoActions): State {
  // Function to extract a value from the array based on field name.
  let f = (addr: Array<AddressComponent>, field: string) => {
    return addr.find(el => {
      if (el.types) return el.types.includes(field);
      return false;
    });
  };

  switch (action.type) {
    case InfoActionsTypes.SaveInfo: {
      const structuredData: StructuredAddressData = f(action.payload.billing, 'structured_address_data').long_name as any;
      const city = structuredData.city
      let address = action.payload.billing
        ? {
          raw: action.payload.billing,
          street_number: f(action.payload.billing, "street_number")
            .short_name,
          route: f(action.payload.billing, "route").short_name,
          postal_code: structuredData.zipCode,
          province: structuredData.province,
          locality: city,
          country: {
            long_name: f(action.payload.billing, "country").long_name,
            short_name: f(action.payload.billing, "country").short_name
          }
        }
        : null;
      return {
        ...state,
        business_name: action.payload.business_name,
        vat: action.payload.vat,
        correspond: action.payload.correspond,
        city_name: action.payload.city_name || "",
        city_id: action.payload.city_id || 0,
        prefix: action.payload.prefix || null,
        country: action.payload.country || null,
        billing_address: address,
        shipping_address: address
      };
    }
    case InfoActionsTypes.SaveInfoShipping: {
      const billingStructuredData: StructuredAddressData = f(action.payload.billing, 'structured_address_data').long_name as any;
      const shippingStructuredData: StructuredAddressData = f(action.payload.shipping, 'structured_address_data').long_name as any;
      return {
        ...state,
        business_name: action.payload.business_name,
        vat: action.payload.vat,
        city_name: action.payload.city_name || "",
        city_id: action.payload.city_id || 0,
        correspond: action.payload.correspond,
        prefix: action.payload.prefix || null,
        country: action.payload.country || null,

        apartment_number: action.payload.apartment_number,
        shipping_name: action.payload.shipping_name,
        billing_address: action.payload.billing
          ? {
            raw: action.payload.billing,
            street_number: f(action.payload.billing, "street_number")
              .short_name,
            route: f(action.payload.billing, "route").short_name,
            postal_code: billingStructuredData.zipCode,
            province: billingStructuredData.province,
            locality: billingStructuredData.city,
            country: {
              long_name: f(action.payload.billing, "country").long_name,
              short_name: f(action.payload.billing, "country").short_name
            }
          }
          : null,
        shipping_address: action.payload.shipping
          ? {
            raw: action.payload.shipping,
            street_number: f(action.payload.shipping, "street_number")
              .short_name,
            route: f(action.payload.shipping, "route").short_name,
            postal_code: shippingStructuredData.zipCode,
            province: shippingStructuredData.province,
            locality: shippingStructuredData.city,
            country: {
              long_name: f(action.payload.shipping, "country").long_name,
              short_name: f(action.payload.shipping, "country").short_name
            }
          }
          : null
      };
    }
    default:
      return state;
  }
}
