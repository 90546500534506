import { CurrencyPipe } from '@angular/common';
import { Pipe } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'countryCurrency',
  pure: false
}) export class CountryCurrencyPipe extends CurrencyPipe {

  private cache;
  // 5 undefined to not fail the check in transform
  private oldValues = [undefined, undefined, undefined, undefined, undefined, undefined];

  constructor(private translateService: TranslateService) {
    super(translateService.currentLang);
  }

  transform(value: any, currencyCode?: string, display?: string, digitsInfo?: string, country?: string, locale: string = this.translateService.currentLang ) {
    country === 'IT' ? locale = 'it' : locale = 'en';
    const currentParams = [value, currencyCode, display, digitsInfo, country, locale];
    if (!this.cache || currentParams.some((el, idx) => el !== this.oldValues[idx])) {
      this.oldValues = currentParams;
      this.cache = super.transform(value, currencyCode, display, digitsInfo, locale);
    }
    return this.cache;
  }
}