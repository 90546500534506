import { Action } from "@ngrx/store";
import { WifiActivationRequest } from "../../models/coverage.model";

export enum CoverageActionsTypes {
    SaveCoverageData = '[Coverage] Save Coverage Data'
}

export class SaveCoverageData implements Action {
    readonly type = CoverageActionsTypes.SaveCoverageData;
    constructor (readonly payload: WifiActivationRequest){}
}

export type CoverageActions = SaveCoverageData;