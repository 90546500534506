import { FlowConfig }from './flow.model';

export const flowStepsConfigGB: FlowConfig = {
  activate_now: {
    steps: [
      "signup",
      "verify-phone",
      "verify-sms",
      "info",
      "account-number",
      "choose-plan8",
      "pay",
      "activation-paid"
    ],
    data: {
      sendSms: true,
      initialCountry: 'GB',
      servedCountries: [
        {
          iso2: 'IT'
        },
        {
          iso2: 'GB',
        },
        {
          iso2: 'US'
        }
      ]
    }
  },
  activate_now_partner: {
    steps: [
      "signup",
      "verify-phone",
      "info",
      "account-number",
      "choose-plan7",
      "pay",
      "activation-paid"
    ],
    data: {
      isPartnerFlow: true,
      redirectToUrl: "free_trial",
      sendSms: false,
      initialCountry: 'GB',
      servedCountries: [
        {
          iso2: 'IT'
        },
        {
          iso2: 'GB',
        },
        {
          iso2: 'US'
        }
      ]
    }
  },
  free_trial: {
    steps: [
      "signup",
      "verify-phone",
      "verify-sms",
      "info",
      "starter-kit",
      "assign-starter-kit",
      "payment-method",
      "activation"
    ],
    data: {
      freetrialPlan: {
        it: "it-trial-v2",
        gb: "gb-trial-v1",
        us: "us-trial-v1"
      },
      askPaymentMethod: true,
      sendSms: true,
      initialCountry: 'GB',
      servedCountries: [
        {
          iso2: 'IT'
        }
      ]
    }
  },
  free_trial_specialist_1: {
    steps: [
      "welcome-specialist",
      "signup",
      "verify-phone",
      "verify-sms",
      "info",
      "starter-kit",
      "assign-starter-kit",
      "payment-method",
      "activation"
    ],
    data: {
      freetrialPlan: {
        it: "it-trial-v2",
        gb: "gb-trial-v1",
        us: "us-trial-v1"
      },
      askPaymentMethod: false,
      linkTokenExp: "free_trial",
      linkTokenNotExist: "free_trial",
      sendSms: true,
      initialCountry: 'GB',
      servedCountries: [
        { iso2: 'IT' },
        { iso2: 'US' }
      ]
    }
  },
  free_trial_partner: {
    steps: [
      "signup",
      "verify-phone",
      "info",
      "payment-method",
      "activation"
    ],
    data: {
      freetrialPlan: {
        it: "it-trial_partner-v1",
        gb: "gb-trial_partner-v1",
        us: "us-trial_partner-v1"
      },
      askPaymentMethod: false,
      isPartnerFlow: true,
      sendSms: false,
      redirectToUrl: "free_trial",
      initialCountry: 'GB',
      servedCountries: [
        {
          iso2: 'IT'
        }
      ]
    }
  },
  activate_offer: {
    steps: [
      "welcome-offer",
      "signup",
      "verify-phone",
      "verify-sms",
      "info",
      "account-number",
      "choose-plan6",
      "pay",
      "activation-paid"
    ],
    data: {
      sendSms: true,
      initialCountry: 'GB',
      servedCountries: [
        {
          iso2: 'IT'
        },
        {
          iso2: 'GB',
        },
        {
          iso2: 'US'
        }
      ]
    }
  },
  activate_now_specialist: {
    steps: [
    "welcome-direct-specialist",
    "signup",
    "verify-phone",
    "verify-sms",
    "info",
    "account-number",
    "choose-plan8",
    "pay",
    "activation-paid"
    ],
    data: {
      sendSms: true,
      initialCountry: 'GB',
      servedCountries: [
        {
          iso2: 'IT'
        },
        {
          iso2: 'GB',
        },
        {
          iso2: 'US'
        }
      ]
    }
  },
  activate_now_partner_specialist: {
    steps: [
    "welcome-direct-specialist",
    "signup",
    "verify-phone",
    "info",
    "account-number",
    "choose-plan7",
    "pay",
    "activation-paid"
    ],
    data: {
      isPartnerFlow: true,
      redirectToUrl: "free_trial",
      sendSms: false,
      initialCountry: 'GB',
      servedCountries: [
        {
          iso2: 'IT'
        },
        {
          iso2: 'GB',
        },
        {
          iso2: 'US'
        }
      ]
    }
  },
  free_trial_partner_specialist: {
    steps: [
    "welcome-specialist",
    "signup",
    "verify-phone",
    "info",
    "payment-method",
    "activation"
    ],
    data: {
      freetrialPlan: {
        it: "it-trial_partner-v1",
        gb: "gb-trial_partner-v1",
        us: "us-trial_partner-v1"
      },
      askPaymentMethod: false,
      isPartnerFlow: true,
      sendSms: false,
      redirectToUrl: "free_trial_partner",
      initialCountry: 'GB',
      servedCountries: [
        {
          iso2: 'IT'
        },
        {
          iso2: 'GB',
        },
        {
          iso2: 'US'
        }
      ]
    }
  }
}
