import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[onbAutofocus]'
})
export class AutofocusDirective implements OnInit {
  private focus = true;
  @Input() focusSelector: string;
  @Input() set onbAutofocus(condition: boolean) {
    this.focus = condition != false;
  }

  constructor(private element: ElementRef) { }

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.focus) {
      let element: HTMLElement = null;
      if (this.focusSelector) {
        element = this.element.nativeElement.querySelectorAll(this.focusSelector)[0];
      } else if (['input', 'textarea'].includes(this.element.nativeElement.localName)) {
        element = this.element.nativeElement;
      } else {
        element = this.element.nativeElement.querySelectorAll('input, textarea')[0];
      }

      if (element) {
        element.focus();
      }
    }
  }
}
