import { NgModule } from "@angular/core";

// Store
import { StoreModule } from "@ngrx/store";
import { signupReducer } from "./store/reducers";
import { SignupFacade } from "./store/facades/signup.facades";
import { SignupService } from "./service";

@NgModule({
  imports: [StoreModule.forFeature("signup", signupReducer)],
  providers: [SignupFacade, SignupService]
})
export class SignupDomainModule {}
