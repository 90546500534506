import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { LegacyAccount } from "../models";
import { forkJoin } from "rxjs";
import { StarterKitFacade } from '../../starter-kit/store/facade';
import { InfoFacade } from '../../info/store/facade';
import { take } from 'rxjs/operators';
import { StarterKit, Equipment } from '../../starter-kit/models';
import { SignupFacade } from '../../signup/store/facades/signup.facades';
import { State } from '../../info/store/reducers';
import { State as signupStatus } from '../../signup/store/reducers';
import { AppFacade } from '../../app/store';
import { TokenResponse } from '../../auth/models/auth.model';

@Injectable()
export class AccountCreationService {
  private activationApi = `${environment.legacyEndpoint}/api/v2/onboarding/create`;
  private activationFormDataAPI = `${environment.legacyEndpoint}/api/v2/onboarding/create-with-upload`;
  private activationInternalAPI = `${environment.legacyEndpoint}/api/v2/onboarding/internal/create`;

  constructor(private http: HttpClient, private starterKitFacade: StarterKitFacade, private infoFacade: InfoFacade, private signupFacade: SignupFacade, private appFacade: AppFacade) { }

  activateAccount(account: LegacyAccount) {
    return this.http.post(this.activationApi, account);
  }

  activateAccountFormData(activationRequest: FormData) {
    return this.http.post(this.activationFormDataAPI, activationRequest);
  }

  activateAccountInternal(account: LegacyAccount, token: TokenResponse) {
    const headers = new HttpHeaders({
      "Authorization": "Bearer " + token.access_token,
      "Voverc-jwt-auth": token.jwt
    });
    return this.http.post(this.activationInternalAPI, account, { headers });
  }

  sendPackageInfoToZapier() {
    forkJoin(this.starterKitFacade.starterKit$.pipe(take(1)),
      this.infoFacade.infoStatus$.pipe(take(1)),
      this.signupFacade.status$.pipe(take(1)),
      this.appFacade.agentID$.pipe(take(1)),
      this.appFacade.partnerID$.pipe(take(1)),
      this.starterKitFacade.equipments$.pipe(take(1))
    ).subscribe(data => {
      const sk: StarterKit = data[0];
      const infoData: State = data[1];
      const signupData: signupStatus = data[2];
      const agentId: string = data[3];
      const partnerId: string = data[4];
      const equipments: Equipment[] = data[5];
      if (sk.id !== "") {
        let info = {} as any;
        const fullnameArr: string[] = infoData.correspond
          ? signupData.fullname.split(' ')
          : infoData.shipping_name.split(' ')
        info.first_name = fullnameArr[0];
        info.last_name = fullnameArr.slice(1).join(' ');
        info.company = infoData.business_name;
        info.email = signupData.email;
        info.package = sk.id;
        if (infoData.correspond) {
          info.shipping_address = `${infoData.billing_address.route}, ${infoData.billing_address.street_number}`;
          info.shipping_city = infoData.billing_address.locality;
          info.shipping_state = infoData.billing_address.country.short_name;
          info.shipping_zip = infoData.billing_address.postal_code;
        } else {
          info.shipping_address = `${infoData.shipping_address.route}, ${infoData.shipping_address.street_number}${infoData.apartment_number ? ',' + infoData.apartment_number : ''}`;
          info.shipping_city = infoData.shipping_address.locality;
          info.shipping_state = infoData.shipping_address.country.short_name;
          info.shipping_zip = infoData.shipping_address.postal_code;
        }
        if (equipments) info.equipments = equipments;
        if (partnerId) info.partner_id = partnerId;
        if (agentId) info.agent_id = agentId;

        const headers = new HttpHeaders().set(
          'Content-Type',
          'application/x-www-form-urlencoded'
        );
        this.http.post(environment.zapierPackageWebhook, info, {
          headers: headers
        }).subscribe();

      }
    });
  }
}
