import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ChoosePlanState, State } from "../reducers";

export const selectChoosePlanState = createFeatureSelector<ChoosePlanState>(
  "choose-plan"
);

export const selectChoosePlanStatus = createSelector(
  selectChoosePlanState,
  (state: ChoosePlanState) => state.status
);

export const selectPlanId = createSelector(
  selectChoosePlanStatus,
  (state: State) => state.planId
);

export const selectDescription = createSelector(
  selectChoosePlanStatus,
  (state: State) => state.description
);

export const selectPrice = createSelector(
  selectChoosePlanStatus,
  (state: State) => state.price
);

export const selectDiscount = createSelector(
  selectChoosePlanStatus,
  (state: State) => state.discount
);

export const selectPlanQuantity = createSelector(
  selectChoosePlanStatus,
  (state: State) => state.quantity
);