<div class="onb-header" [ngClass]="colorClass">
  <picture class="onb-header__logo">
    <source media="(max-width: 992px)" [srcset]="mobileLogo">
    <img [src]="desktopLogo" alt="voxloud logo" width="150" height="30">
  </picture>
  <div class="onb-header__actions">
    <i class="icon-small-call"></i>
    <span>{{'shared.header.help' | translate}}</span>
    <div class="vox-dropdown dropdown">
      <button class="dropdown-toggle" role="button" id="dropdownPhoneNumbers" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="flag-icon flag-icon-squared {{selectedPhoneNumber.flagClass}}"></span> <b>{{selectedPhoneNumber.number}}</b>
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownPhoneNumbers">
        <button class="dropdown-item"
        *ngFor="let phoneNumber of phoneNumbers"
        (click)="onSelectPhoneNumber(phoneNumber)">
          <span class="flag-icon flag-icon-squared {{phoneNumber.flagClass}}"></span> {{phoneNumber.number}}
        </button>
      </div>
    </div>
    <vox-language-picker [language]="currentLang" [currentLanguage]="currentLang" [availableLanguages]="langs"
      (selected)="onChange($event)"
      [side]='currentWidth'>
    </vox-language-picker>
  </div>
  <div class="vox-dropdown dropdown onb-header__mobile-phone">
    <button class="dropdown-toggle" role="button" id="dropdownMobilePhoneNumbers" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i class="icon-small-call"></i>
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMobilePhoneNumbers">
      <a href="tel:{{phoneNumber.number}}" class="dropdown-item"
      *ngFor="let phoneNumber of phoneNumbers"><span class="flag flag-select flag-icon {{phoneNumber.flagClass}}"></span> {{phoneNumber.number}}</a>
    </div>
  </div>
</div>