import { State, reducer } from './signup.reducers';
import { ActionReducerMap } from '@ngrx/store';

export interface SignupState {
  signupStatus: State;
}

export const signupReducer: ActionReducerMap<SignupState> = {
  signupStatus: reducer
};

export * from './signup.reducers';
