import { BrowserModule } from "@angular/platform-browser";
import { NgModule, Injector, Inject } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpClientModule } from "@angular/common/http";

// Translations
import { TranslateModule, TranslateLoader, TranslateService, LangChangeEvent } from "@ngx-translate/core";
// Components
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";

// Modules
import { DomainsModule } from "./domains/domains.module";
import { LaddaModule } from "angular2-ladda";
import { CoreModule } from "./core/core.module";

import { IntercomModule } from "ng-intercom";

import localeIt from "@angular/common/locales/it";
import { registerLocaleData } from '@angular/common';
import { ReferrerModalComponent } from './features/signup/components/referrer-modal/referrer-modal.component';
import { SharedModule } from "./shared/shared.module";
import { TranslateLoaderForFeature } from "./core/i18n/flow-translation-http-loader";
import { I18nService } from "./core/i18n";
registerLocaleData(localeIt);

export function createTranslateLoader(http: HttpClient){
  return new TranslateLoaderForFeature(http,'app');
}


@NgModule({
    declarations: [AppComponent, ReferrerModalComponent],
    imports: [
        BrowserModule,
        CoreModule,
        SharedModule,
        HttpClientModule,
        AppRoutingModule,
        DomainsModule,
        LaddaModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            },
            isolate: true,
            extend: true
        }),
        IntercomModule.forRoot({
            appId: environment.intercom_app_id,
            updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
        })
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private translate: TranslateService, private i18n: I18nService){
    this.i18n.languageChange.subscribe((event: LangChangeEvent) => {
      this.translate.use(event.lang);
    })
    this.translate.use(this.i18n.language);
  }
}
