import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'onb-timer-countdown',
  templateUrl: './timer-countdown.component.html',
  styleUrls: ['./timer-countdown.component.scss']
})
export class TimerCountdownComponent implements OnInit {
  @Input() expireDate: Date;
  @Input() countdownTitle: string = "";
  public expiresDateMillis: number;
  public currentDateMillis: number = Date.now();
  public timeLeftMillis: number;
  public daysLeft: number;
  public hoursLeft: number;
  public minutesLeft: number;
  public secondsLeft: number;
  public countdownInterval = null;
  public expiredToken: boolean = false;
  private remainingTimeMillis: number;
  @Output() expired: EventEmitter<boolean> = new EventEmitter();

  // variables to sync view with logic
  public changeDaysTens: boolean = false;
  public changeDaysUnit: boolean = false;
  public changeHoursTens: boolean = false;
  public changeHoursUnit: boolean = false;
  public changeMinutesTens: boolean = false;
  public changeMinutesUnit: boolean = false;
  public changeSecondsTens: boolean = false;
  public changeSecondsUnit: boolean = false;

  constructor(private translate: TranslateService) { }

  ngOnInit() {
    // take date from token
    this.expiresDateMillis = this.expireDate ? this.expireDate.getTime() : 0;
    this.timeLeftMillis = this.expiresDateMillis - this.currentDateMillis;
    if (this.timeLeftMillis < 0) {
      this.setExpiredTime();
      this.expired.emit(this.expiredToken);
    } else {
      this.setRemainingTime();
      this.countTime();
    }
  }

  // Methods for number format
  getTensDigit(p) {
    return Math.floor(p / 10);
  }

  getUnitNumber(p) {
    return +(p % 10).toString();
  }

  getFormatDate(date: Date) {
    var options: Intl.DateTimeFormatOptions = {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric"
    };
    var newDate;
    if (this.translate.currentLang === "it") {
      newDate = new Date(date).toLocaleString("it-IT", options);
    }
    if (this.translate.currentLang === "en") {
      newDate = new Date(date).toLocaleString("en-US", options);
    }
    return newDate;
  }

  setExpiredTime() {
    this.daysLeft = 0;
    this.hoursLeft = 0;
    this.minutesLeft = 0;
    this.secondsLeft = 0;
    this.expiredToken = true;
  }

  // calculate days, hours, minutes, seconds
  setRemainingTime() {
    var delta =
      Math.abs(this.expiresDateMillis - this.currentDateMillis) / 1000;

    this.remainingTimeMillis = delta;

    this.daysLeft = Math.floor(delta / 86400);
    delta -= this.daysLeft * 86400;

    this.hoursLeft = Math.floor(delta / 3600) % 24;
    delta -= this.hoursLeft * 3600;

    this.minutesLeft = Math.floor(delta / 60) % 60;
    delta -= this.minutesLeft * 60;

    this.secondsLeft = Math.floor(delta % 60);

    this.expiredToken = false;
  }

  countTime() {
    this.countdownInterval = setInterval(() => {
      this.remainingTimeMillis -= 1;
      if (this.remainingTimeMillis < 0) {
        this.stopInterval();
        return;
      }
      // manage seconds
      this.changeSecondsUnit = true;
      if (this.getUnitNumber(this.secondsLeft) === 0) {
        this.changeSecondsTens = true;
      } else {
        this.changeSecondsTens = false;
      }
      this.secondsLeft = this.secondsLeft - 1;
      // manage minutes
      if (this.minutesLeft >= 0 && this.secondsLeft < 0) {
        this.secondsLeft = 59;
        this.changeMinutesUnit = true;
        if (this.secondsLeft === 0) {
          this.changeMinutesTens = true;
        } else {
          this.changeMinutesTens = false;
        }
        this.minutesLeft = this.minutesLeft - 1;
      } else {
        this.changeMinutesUnit = false;
      }
      // manage hours
      if (this.hoursLeft >= 0 && this.minutesLeft < 0) {
        this.minutesLeft = 59;
        this.changeHoursUnit = true;
        if (this.minutesLeft === 0) {
          this.changeHoursTens = true;
        } else {
          this.changeHoursTens = false;
        }
        this.hoursLeft = this.hoursLeft - 1;
      } else {
        this.changeHoursUnit = false;
      }
      // manage days
      if (this.daysLeft >= 0 && this.hoursLeft < 0) {
        this.hoursLeft = 24;
        this.changeDaysUnit = true;
        if (this.hoursLeft === 0) {
          this.changeDaysTens = true;
        } else {
          this.changeDaysTens = false;
        }
        this.daysLeft = this.daysLeft - 1;
      } else {
        this.changeDaysUnit = false;
      }
    }, 1000);
  }


  private stopInterval() {
    clearInterval(this.countdownInterval);
    this.setExpiredTime();
    this.expired.emit(true);
    this.changeDaysTens = false;
    this.changeDaysUnit = false;
    this.changeHoursTens = false;
    this.changeHoursUnit = false;
    this.changeMinutesTens = false;
    this.changeMinutesUnit = false;
    this.changeSecondsTens = false;
    this.changeSecondsUnit = false;
  }

}
