import { NgModule, Optional, SkipSelf } from "@angular/core";
import { interceptors } from "./http/interceptors";
import { ErrorComponent } from "../shared/components/errors/layout/error.component";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../shared/shared.module";

@NgModule({
  declarations: [],
  imports: [CommonModule, SharedModule],
  exports: [],
  providers: [...interceptors]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error("CoreModule is already loaded. Import only in AppModule");
    }
  }
}
