import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { AppState } from '../../../domains/app/store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectLocale } from '../../../domains/app/store';
import { take, switchMap } from 'rxjs/operators';

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.store.select(selectLocale).pipe(
      take(1),
      switchMap((locale: string) => {
        const clonedRequest = request.clone({
          headers: request.headers.set('Accept-Language', locale || 'all')
        });
        return next.handle(clonedRequest);
      })
    );
  }
}
