import { VerifyPhoneActions, VerifyPhoneActionsTypes } from '../actions';

export interface State {
  code: string;
  loading: boolean;
  error: string;
  number: string;
  token: string;
}

export const initialState: State = {
  number: '',
  code: '',
  loading: false,
  error: null,
  token: ''
};

export function reducer(
  state = initialState,
  action: VerifyPhoneActions
): State {
  switch (action.type) {
    case VerifyPhoneActionsTypes.DropNumber: {
      return {
        ...state,
        number: ''
      };
    }
    case VerifyPhoneActionsTypes.SendNumber: {
      return {
        ...state,
        number: action.payload,
        loading: true
      };
    }
    case VerifyPhoneActionsTypes.SaveNumber: {
      return {
        ...state,
        number: action.payload,
        loading: true
      };
    }
    case VerifyPhoneActionsTypes.ResendNumber: {
      return {
        ...state,
        number: action.payload,
        loading: true
      };
    }
    case VerifyPhoneActionsTypes.ResendNumberSuccess: {
      return {
        ...state,
        token: action.payload,
        loading: false
      };
    }
    case VerifyPhoneActionsTypes.SendNumberSuccess: {
      return {
        ...state,
        token: action.payload,
        loading: false
      };
    }
    case VerifyPhoneActionsTypes.SendNumberFailure: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }
    case VerifyPhoneActionsTypes.SendCode: {
      return {
        ...state,
        loading: true,
        error: null,
        code: action.payload.code
      };
    }
    case VerifyPhoneActionsTypes.SendCodeSuccess: {
      return {
        ...state,
        loading: false,
        error: null
      };
    }
    case VerifyPhoneActionsTypes.SendCodeFailure: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }
    default:
      return state;
  }
}
