import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { TokenModel } from "src/app/features/welcome-specialist/layout";

@Injectable({ providedIn: "root" })
export class TimerService {
  private _token: Subject<TokenModel> = new Subject();
  private _shown: Subject<boolean> = new Subject();

  constructor() {}

  setToken(token: TokenModel) {
    this._token.next(token);
  }

  getToken(): Subject<TokenModel> {
    return this._token;
  }

  setShown(shown: boolean) {
    this._shown.next(shown);
  }

  getShown(): Subject<boolean> {
    return this._shown;
  }

  showTimer(token: TokenModel) {}
}
