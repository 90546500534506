import { State, reducer } from './activation.reducer';
import { ActionReducerMap } from '@ngrx/store';

export * from './activation.reducer';

export interface ActivationState {
  status: State;
}

export const activationReducer: ActionReducerMap<ActivationState> = {
  status: reducer
};
