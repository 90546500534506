import { VDValues, VerificationDataRequirements } from 'ng-voxloud';
import { PortabilityDocuments, PortabilityNumber } from '../../models/portability.model';
import { AccountDataActions, AccountDataActionTypes } from '../actions/account-number.actions';

export interface State {
  verificationDataRequirements: VerificationDataRequirements;
  hasRequirements: boolean;
  hasVerificationData: boolean;
  type: string;
  verificationData: VDValues;
  documents: PortabilityDocuments;
  numbersToPort: PortabilityNumber[];
}

export const initialState: State = {
  verificationDataRequirements: null,
  hasRequirements: false,
  hasVerificationData: false,
  type: null,
  verificationData: null,
  documents: null,
  numbersToPort: null
};

export function reducer(state: State = initialState, action: AccountDataActions): State {
  switch (action.type) {
    case AccountDataActionTypes.SaveVerificationDataRequirements:
      return {
        ...state,
        verificationDataRequirements: action.payload,
        hasRequirements: !!(action.payload && (action.payload.BUSINESS || action.payload.PRIVATE))
      }
    case AccountDataActionTypes.SaveVerificationData:
      return {
        ...state,
        verificationData: action.payload.vd,
        type: action.payload.type,
        hasVerificationData: !!action.payload.vd
      }
    case AccountDataActionTypes.savePortabilityDocuments:
      return {
        ...state,
        documents: action.payload ? action.payload.documents : null,
        numbersToPort: action.payload ? action.payload.numbers : null
      }
    default:
      return state;
  }
}
