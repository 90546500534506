import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class StarterKitService {
  private getAddonsApi = `${environment.legacyEndpoint}/api/v1/subscriptions/addons`;

  constructor(private http: HttpClient) {}

  public getAddons(id: string) {
    return this.http.get(`${this.getAddonsApi}/${id}`);
  }
}