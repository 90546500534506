import { Component, OnInit } from "@angular/core";

declare global {
  interface Window {
    Trustpilot: any;
  }
}

@Component({
  selector: "onb-trustbox-list-dark-en",
  templateUrl: "./trustbox-list-dark-en.component.html",
  styleUrls: ["./trustbox-list-dark-en.component.scss"]
})
export class TrustboxListDarkEnComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    const trustboxRefEN = document.getElementById("trustbox-list-dark-en");
    window.Trustpilot.loadFromElement(trustboxRefEN);
  }
}
