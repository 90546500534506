import { ActionReducerMap } from '@ngrx/store';
import { reducer, State } from './store/reducers/account-number.reducer';

export * from './store/actions/account-number.actions';
export * from './store/facade/account-number.facade';
export * from './store/reducers/account-number.reducer';
export * from './store/selectors/account-number.selector';
export * from './services/account-number.service';

export interface VerificationDataState {
  account_number: State
};

export const verificationDataReducer: ActionReducerMap<VerificationDataState> = {
  account_number: reducer
};