import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, withLatestFrom, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Logger } from 'src/app/core/logger';
import { AppActionTypes, DecrementStep, IncrementStep, JumpToFirstStep } from '../actions';
import { AppState } from '../reducers';
import { selectStep } from '../selectors';


@Injectable()
export class AppEffects {
  private log = new Logger('App Effects');

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<AppState>
  ) {}

  incrementStep = createEffect(
    () => this.actions$.pipe(
      ofType(AppActionTypes.IncrementStep),
      withLatestFrom(this.store.select(selectStep)),
      // TODO: refactor this
      map((val: [never, string]) => {
        const route = val[1].replace('.', '/');
        this.router.navigate([route]);
      })
    ),
    { dispatch: false }
  )

  decrementStep = createEffect(
    () => this.actions$.pipe(
      ofType(AppActionTypes.DecrementStep),
      withLatestFrom(this.store.select(selectStep)),
      // TODO: refactor this
      map((val) => {
        const route = val[1].replace('.', '/');
        this.router.navigate([route]);
      })
    ),
    { dispatch: false }
  )


  // TODO: Remove when we don't have to hide stater kits
  skipStarterKits = createEffect(
    () => this.actions$.pipe(
        ofType(AppActionTypes.SkipStarterKits),
        withLatestFrom(this.store.select(selectStep)),
        // TODO: refactor this
        map((val) => {
          const route = val[1].replace('.', '/');
          this.router.navigate([route]);
        })
      ),
      { dispatch: false }
  )


  skipNextNSteps = createEffect(
    () => this.actions$.pipe(
        ofType(AppActionTypes.SkipNextNSteps),
        withLatestFrom(this.store.select(selectStep)),
        // TODO: refactor this
        map((val) => {
          const route = val[1].replace('.', '/');
          this.router.navigate([route]);
        })
      ),
      { dispatch: false }
  )

}
