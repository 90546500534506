<div class="onb-container" [ngClass]="direction">
  <div class="vox-progress progress">
    <div class="vox-progress__bar vox-progress__bar_bright-crimson progress-bar" role="progressbar" [style.width] ="progressWidth"></div>
  </div>
  <section class="onb-container_left">
    <ng-content select="[section1]"></ng-content>
  </section>
  <section class="onb-container_right" [ngClass]="backgroundColor">
    <ng-content select="[section2]"></ng-content>
  </section>
</div>