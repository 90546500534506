import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { choosePlanReducer } from './store/reducers';
import { ChoosePlanFacade } from './store/facade';
import { ChoosePlanService } from './services/choose-plan.service';

@NgModule({
  imports: [StoreModule.forFeature('choose-plan', choosePlanReducer)],
  providers: [
    ChoosePlanFacade,
    ChoosePlanService
  ]
})
export class ChoosePlanDomainModule {}
