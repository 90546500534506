import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { AddonDto } from "../model";

@Injectable()
export class AddonService {

  constructor(
    private http: HttpClient
  ){}

  public getAddonDetails(addonId: string){
    return this.http.get<AddonDto>(`${environment.legacyEndpoint}/api/v1/subscriptions/addons/${encodeURIComponent(addonId)}`);
  }
}