import { forkJoin, Observable, of } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';

import { HttpClient } from '@angular/common/http';
import { flowStepsConfig } from 'src/app/domains/app/model/app.model';
import { catchError, map, tap } from 'rxjs/operators';
import { Inject } from '@angular/core';

import {merge} from 'lodash';


const DiffenceFlowTranslationMap = {
  signup: ['free_trial', 'free_trial_partner', 'free_trial_partner_specialist', 'free_trial_specialist', 'free_trial_specialist_1', 'prova_gratis', 'prova_gratis_partner', 'prova_gratis_partner_specialista', 'prova_gratis_specialista', 'prova_gratis_specialista_1'],
  activation: ['free_trial', 'free_trial_partner', 'free_trial_partner_specialist', 'free_trial_specialist', 'free_trial_specialist_1', 'prova_gratis', 'prova_gratis_partner', 'prova_gratis_partner_specialista', 'prova_gratis_specialista', 'prova_gratis_specialista_1'],
  "activation-recap": ['free_trial_specialist_1', 'prova_gratis_specialista_1'],
  pay: ['free_trial_specialist_1', 'prova_gratis_specialista_1'],
  
}

export class TranslateLoaderForFeature implements TranslateLoader{
  currentFlow = '';

  constructor(private http: HttpClient, private feature: string, private tokenName: string = ''){
    this.currentFlow = document.location.pathname.split('/').slice(1)[0];
  }

   getTranslation(lang: string): Observable<any> {
    const needDifference = Boolean(DiffenceFlowTranslationMap[this.feature]?.includes(this.currentFlow));
    const differenceObservable = needDifference ?
                                 this.http.get(`assets/i18n/${this.feature}/${this.currentFlow}/${lang}.json`).pipe(catchError(e => of({}))) :
                                 of({})
    return forkJoin({
      common: this.http.get(`assets/i18n/${this.feature}/${lang}.json`).pipe(catchError(e => of({}))),
      differences: differenceObservable,
      shared: this.http.get(`assets/i18n/shared/${lang}.json`).pipe(catchError(e => of({})))
    }).pipe(map((v: {
      common: any,
      differences: any,
      shared: any
    }) => {
      if(needDifference){
        const merged = merge(v.common, v.differences);
        return {
          [this.tokenName || this.feature]: merged,
          'shared': {
            ...v.shared
          } 
        }
      }
      return {
        [this.tokenName || this.feature]: {
          ...v.common,
          ...v.differences
        },
        'shared': {
          ...v.shared
        }
      }
    }));
  }
}
