import { Injectable } from "@angular/core";
import { Intercom } from "ng-intercom";

@Injectable({ providedIn: "root" })
export class IntercomChatService {
  constructor(public intercom: Intercom) {}

  show() {
    this.intercom.show();
  }

  hide() {
    this.intercom.hide();
  }

  showNewMessage(message: string) {
    this.intercom.showNewMessage(message);
  }

  update(data: any){
    this.intercom.update(data);
  }

  trackEvent(eventName: string, metadata?: any){
    this.intercom.trackEvent(eventName, metadata);
  }
}
