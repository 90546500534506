import { Action } from '@ngrx/store';
import { Account } from '../../models';

export enum ActivationActionsTypes {
  sendAccount = '[Activation] Send Account',
  setNewNumber = '[Activation] Set New Number',
  sendAccountSuccess = '[Activation] Send Account Success',
  sendAccountFailure = '[Activation] Send Account Failure'
}

export class SendAccount implements Action {
  readonly type = ActivationActionsTypes.sendAccount;
  constructor(readonly payload: Account) {}
}

export class SendAccountSuccess implements Action {
  readonly type = ActivationActionsTypes.sendAccountSuccess;
  constructor(readonly payload: Account) {}
}

export class SendAccountFailure implements Action {
  readonly type = ActivationActionsTypes.sendAccountFailure;
  constructor(readonly payload: { error: any }) {}
}

export class SetNewNumber implements Action {
  readonly type = ActivationActionsTypes.setNewNumber;
  constructor(readonly payload: string) {}
}

export type ActivationActions =
  | SendAccount
  | SendAccountSuccess
  | SendAccountFailure
  | SetNewNumber;
