import { Action } from "@ngrx/store";
import { AssignedUser, Equipment } from '../../models';

export enum StarterKitActionsTypes {
  storeStarterKit = "[Starter Kit] Store Starter Kit",
  removeStarterKit = "[Starter Kit] Remove Starter Kit",
  storeStarterKitEquipments = "[Starter Kit] Store Starter Kit Equipments"
}

export class StoreStarterKit implements Action {
  readonly type = StarterKitActionsTypes.storeStarterKit;
  constructor(readonly id: string, readonly addon_id: string) {}
}

export class RemoveStarterKit implements Action {
  readonly type = StarterKitActionsTypes.removeStarterKit;
  constructor(readonly id: string, readonly addon_id: string) {}
}

export class StoreStarterKitEquipments implements Action {
  readonly type = StarterKitActionsTypes.storeStarterKitEquipments;
  constructor(readonly equipments: Equipment[]) {}
}

export type StarterKitActions = StoreStarterKit | RemoveStarterKit | StoreStarterKitEquipments;
