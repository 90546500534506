import { State, reducer } from "./addon.reducers";
import { ActionReducerMap } from "@ngrx/store";

export * from "./addon.reducers";

export interface AddonState {
  status: State;
}

export const addonReducer: ActionReducerMap<AddonState> = {
  status: reducer
};
