import { NgModule } from "@angular/core";
import {
  RouterModule,
  Route,
  Routes,
  PreloadAllModules
} from "@angular/router";

// plain list of steps to be used in more complex configuration
// generate it in app.component using function from app.model
const stepRoutes: Routes = [
  {
    path: "signup",
    loadChildren: () => import('./features/signup/signup.module').then(m => m.SignupModule)
  },
  {
    path: "verify-phone",
    loadChildren:
      () => import('./features/verify-phone/verify-phone.module').then(m => m.VerifyPhoneModule)
  },
  {
    path: "verify-sms",
    loadChildren: () => import('./features/verify-sms/verify-sms.module').then(m => m.VerifySmsModule)
  },
  {
    path: "info",
    loadChildren: () => import('./features/info/info.module').then(m => m.InfoModule)
  },
  {
    path: "activation-paid",
    loadChildren: () => import('./features/activation/activation.module').then(m => m.ActivationModule)
  },
  {
    path: "activation",
    loadChildren: () => import('./features/activation/activation.module').then(m => m.ActivationModule)
  },
  {
    path: "addon",
    loadChildren: () => import('./features/addon/addon.module').then(m => m.AddonModule)
  },
  {
    path: "choose-plan3",
    loadChildren: () => import('./features/choose-plan3/choose-plan3.module').then(m => m.ChoosePlan3Module)
  },
  {
    path: "choose-plan2",
    loadChildren: () => import('./features/choose-plan2/choose-plan2.module').then(m => m.ChoosePlan2Module)
  },
  {
    path: "choose-plan4",
    loadChildren: () => import('./features/choose-plan-discount/choose-plan-discount.module').then(m => m.ChoosePlanDiscountModule)
  },
  {
    path: "choose-plan5",
    loadChildren: () => import('./features/choose-plan-discount-partner/choose-plan-discount-partner.module').then(m => m.ChoosePlanDiscountPartnerModule)
  },
  {
    path: "choose-plan6",
    loadChildren: () => import('./features/choose-plan-annual-biennial/choose-plan-annual-biennial.module').then(m => m.ChoosePlanAnnualBiennialModule)
  },
  {
    path: "choose-plan7",
    loadChildren: () => import('./features/choose-plan-annual-biennial-partner/choose-plan-annual-partner.module').then(m => m.ChoosePlanAnnualBiennialPartnerModule)
  },
  {
    path: "choose-plan0",
    loadChildren: () => import('./features/choose-plan-internal/choose-plan-internal.module').then(m => m.ChoosePlanInternalModule)
  },
  {
    path: "choose-plan8",
    loadChildren: () => import('./features/choose-plan-annual-v4/choose-plan-annual-v4.module').then(m => m.ChoosePlanAnnualV4Module)
  },
  {
    path: "pay",
    loadChildren: () => import('./features/pay/pay.module').then(m => m.PayModule)
  },
  {
    path: "payment-method",
    loadChildren: () => import('./features/activation-recap/activation-recap.module').then(m => m.ActivationRecapModule)
  },
  {
    path: "starter-kit",
    loadChildren: () => import('./features/starter-kit/starter-kit.module').then(m => m.StarterKitModule)
  },
  {
    path: "assign-starter-kit",
    loadChildren: () => import('./features/assign-starter-kit/assign-starter-kit.module').then(m => m.AssignStarterKitModule)
  },
  {
    path: "welcome-specialist",
    loadChildren: () => import('./features/welcome-specialist/welcome-specialist.module').then(m => m.WelcomeSpecialistModule)
  },
  {
    path: "welcome-offer",
    loadChildren: () => import('./features/welcome-offer/welcome-offer.module').then(m => m.WelcomeOfferModule)
  },
  {
    path: "welcome-direct-specialist",
    loadChildren: () => import('./features/welcome-direct-specialist/welcome-direct-specialist.module').then(m => m.WelcomeDirectSpecialistModule)
  },
  {
    path: 'wifi',
    loadChildren: () => import('./features/coverage/coverage.module').then(m => m.CoverageModule)
  },
  {
    path: 'wifi-full',
    loadChildren: () => import('./features/coverage-full/coverage-full.module').then(m => m.CoverageFullModule)
  },
  {
    path: "account-number",
    loadChildren: () => import('./features/account-number/account-number.module').then(m => m.VerificationDataModule)
  }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(stepRoutes, {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: "enabled",
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
