import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { infoReducer } from "./store/reducers";
import { InfoFacade } from "./store/facade";
import { InfoService } from './service/info.service';

@NgModule({
  imports: [StoreModule.forFeature("info", infoReducer)],
  providers: [InfoFacade, InfoService]
})
export class InfoDomainModule {}
