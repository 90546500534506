import { NgModule } from '@angular/core';
import { Store, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { activationReducer } from './store/reducers';
import { ActivationFacade } from './store/facade';
import { AccountCreationService } from './services';
import { ActivationEffects } from './store/effects';

@NgModule({
  imports: [
    StoreModule.forFeature('activation', activationReducer),
    EffectsModule.forFeature([ActivationEffects])
  ],
  providers: [ActivationFacade, AccountCreationService]
})
export class ActivationDomainModule {}
