import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { AddonState } from "../reducers";
import { selectAddons } from "../selectors";
import { StoreAddons, StoreNumberAddon, StoreSkAddons } from "../actions";
import { Addon, Addons } from "../../model";

@Injectable()
export class AddonFacade {
  constructor(private store: Store<AddonState>) {}
  
  addons$ = this.store.pipe(select(selectAddons));

  

  storeAddons(addons: Addons) {
    this.store.dispatch(new StoreAddons(addons));
  }

  storeNumberAddon(addon: Addon) {
    this.store.dispatch(new StoreNumberAddon(addon));
  }

  storeSkAddons(addons: Addons) {
    this.store.dispatch(new StoreSkAddons(addons));
  }
}
