import { Injectable } from '@angular/core';
import { Logger } from 'src/app/core/logger';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { AccountCreationService } from '../../services';
import {
  ActivationActionsTypes,
  SendAccount,
  SendAccountSuccess,
  SendAccountFailure
} from '../actions';
import { map, switchMap, catchError } from 'rxjs/operators';
import { Account } from '../../models';
import { of } from 'rxjs';

const log = new Logger('Activation Effects');

@Injectable()
export class ActivationEffects {

  constructor(
    private actions$: Actions,
    private service: AccountCreationService
  ) {}
}
