import { PlanDto } from "../../models";
import { ChoosePlanActions, ChoosePlanActionTypes } from "../actions";

export interface State {
  planId: string;
  description: string;
  price: number;
  discount: number;
  quantity?: number;
}

export const initialState: State = {
  planId: "",
  description: "",
  price: null,
  discount: null,
  quantity: null
};

export function reducer(
  state: State = initialState,
  action: ChoosePlanActions
) {
  switch (action.type) {
    case ChoosePlanActionTypes.SavePlanId:
      return {
        ...state,
        planId: action.payload.planId,
        description: action.payload.description,
        price: action.payload.price / 100,
        discount: action.payload.discount,
        quantity: action.payload.quantity
      };
      case ChoosePlanActionTypes.SavePlanQuantity:
        return {
          ...state,
          quantity: action.quantity
        };
    default:
      return state;
  }
}
