import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { VDValues } from 'ng-voxloud';
import { VerificationDataResponse } from 'src/app/domains/info/models/info.model';
import { savePortabilityDocuments, SaveVerificationData, SaveVerificationDataRequirements, selectHasVerificationData, selectHasVerificationDataRequirements, selectPortabilityDocuments, selectVerificationData, selectVerificationDataRequirements, VerificationDataState } from '../..';
import { PortabilityData } from '../../models/portability.model';
import { selectPortabilityNumbers } from '../selectors/account-number.selector';

@Injectable()
export class AccountNumberFacade {

  verificationDataRequirements$ = this.store.pipe(select(selectVerificationDataRequirements));
  hasRequirements$ = this.store.pipe(select(selectHasVerificationDataRequirements));
  hasVerificationData$ = this.store.pipe(select(selectHasVerificationData));
  verificationData$ = this.store.pipe(select(selectVerificationData));
  portabilityDocuments$ = this.store.pipe(select(selectPortabilityDocuments));
  portabilityNumbers$ = this.store.pipe(select(selectPortabilityNumbers));

  constructor(private store: Store<VerificationDataState>) { }

  saveVerificationDataRequirements(vdRequirements: VerificationDataResponse) {
    this.store.dispatch(new SaveVerificationDataRequirements(vdRequirements));
  }

  saveVerificationData(vd: VDValues, type: string) {
    this.store.dispatch(new SaveVerificationData({ vd, type }));
  }

  savePortabilityDocuments(pData: PortabilityData){
    this.store.dispatch(new savePortabilityDocuments(pData));
  }
}