import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable, throwError, of } from "rxjs";
import { Injectable } from "@angular/core";
import { catchError } from "rxjs/operators";
import { Logger } from "../../logger";
import { environment } from "src/environments/environment";
import { ErrorService } from "../../../shared/components/errors/service/error.service";
import { ERRORTYPES } from "../../../shared/components/errors/service/error.model";

const log = new Logger("ErrorHandler Interceptor");
const errorCodes = [500, 401];
const activationApi = `${environment.legacyEndpoint}/api/v2/onboarding/create`;

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(catchError(error => this.errorHandler(error)));
  }

  constructor(private errorService: ErrorService) {}

  private errorHandler(event: HttpEvent<any>) {
    if (!environment.production) {
      if (event instanceof HttpResponse) {
        log.error("Request error", event.body);
      }
    }
    if (event instanceof HttpErrorResponse) {
      if (errorCodes.includes(event.status)) {
        if (event.url !== activationApi && !event.url.includes('/presence')) {
          this.errorService.throwError({
            type: ERRORTYPES.CRITICAL,
            message:
              event.error && event.error.message
                ? event.error.message
                : event.message
          });
        }
      }
    }
    return throwError(event);
  }
}
