<div class="timer-container" *ngIf="shown">
  <div class="timer-box">
    <p class="timer-box__subtitle">
      {{ "shared.trial-video-timer.timer.subtitle-valid" | translate }}
    </p>
    <h2 class="timer-box__title">{{ getFormatDate(token.date) }}</h2>

    <div class="countdown">
      <div class="bloc-time days">
        <div class="figure days-1">
          <span class="top" [ngClass]="{ 'animation-top': changeDaysTens }">{{
            getTensDigit(daysLeft)
          }}</span>
          <span class="top-back" [ngClass]="{ 'animation-top-back': changeDaysTens }">
            <span>{{ getTensDigit(daysLeft) }}</span>
          </span>
          <span class="bottom">{{ getTensDigit(daysLeft) }}</span>
          <span class="bottom-back">
            <span>{{ getTensDigit(daysLeft) }}</span>
          </span>
        </div>

        <div class="figure days-2">
          <span class="top" [ngClass]="{ 'animation-top': changeDaysUnit }">{{
            getUnitNumber(daysLeft)
          }}</span>
          <span class="top-back" [ngClass]="{ 'animation-top-back': changeDaysUnit }">
            <span>{{ getUnitNumber(daysLeft) }}</span>
          </span>
          <span class="bottom">{{ getUnitNumber(daysLeft) }}</span>
          <span class="bottom-back">
            <span>{{ getUnitNumber(daysLeft) }}</span>
          </span>
        </div>
        <span class="count-title">{{
          "shared.trial-video-timer.days" | translate
        }}</span>
      </div>

      <div class="bloc-time hours">
        <div class="figure hours-1">
          <span class="top" [ngClass]="{ 'animation-top': changeHoursTens }">{{
            getTensDigit(hoursLeft)
          }}</span>
          <span class="top-back" [ngClass]="{ 'animation-top-back': changeHoursTens }">
            <span>{{ getTensDigit(hoursLeft) }}</span>
          </span>
          <span class="bottom">{{ getTensDigit(hoursLeft) }}</span>
          <span class="bottom-back">
            <span>{{ getTensDigit(hoursLeft) }}</span>
          </span>
        </div>

        <div class="figure hours-2">
          <span class="top" [ngClass]="{ 'animation-top': changeHoursUnit }">{{
            getUnitNumber(hoursLeft)
          }}</span>
          <span class="top-back" [ngClass]="{ 'animation-top-back': changeHoursUnit }">
            <span>{{ getUnitNumber(hoursLeft) }}</span>
          </span>
          <span class="bottom">{{ getUnitNumber(hoursLeft) }}</span>
          <span class="bottom-back">
            <span>{{ getUnitNumber(hoursLeft) }}</span>
          </span>
        </div>
        <span class="count-title">{{
          "shared.trial-video-timer.hours" | translate
        }}</span>
      </div>

      <div class="bloc-time min">
        <div class="figure min-1">
          <span class="top" [ngClass]="{ 'animation-top': changeMinutesTens }">{{ getTensDigit(minutesLeft) }}</span>
          <span class="top-back" [ngClass]="{ 'animation-top-back': changeMinutesTens }">
            <span>{{ getTensDigit(minutesLeft) }}</span>
          </span>
          <span class="bottom">{{ getTensDigit(minutesLeft) }}</span>
          <span class="bottom-back">
            <span>{{ getTensDigit(minutesLeft) }}</span>
          </span>
        </div>

        <div class="figure min-2">
          <span class="top" [ngClass]="{ 'animation-top': changeMinutesUnit }">{{ getUnitNumber(minutesLeft) }}</span>
          <span class="top-back" [ngClass]="{ 'animation-top-back': changeMinutesUnit }">
            <span>{{ getUnitNumber(minutesLeft) }}</span>
          </span>
          <span class="bottom">{{ getUnitNumber(minutesLeft) }}</span>
          <span class="bottom-back">
            <span>{{ getUnitNumber(minutesLeft) }}</span>
          </span>
        </div>
        <span class="count-title">{{
          "shared.trial-video-timer.minutes" | translate
        }}</span>
      </div>

      <div class="bloc-time sec">
        <div class="figure sec-1">
          <span class="top" [ngClass]="{ 'animation-top': changeSecondsTens }">{{ getTensDigit(secondsLeft) }}</span>
          <span class="top-back" [ngClass]="{ 'animation-top-back': changeSecondsTens }">
            <span>{{ getTensDigit(secondsLeft) }}</span>
          </span>
          <span class="bottom">{{ getTensDigit(secondsLeft) }}</span>
          <span class="bottom-back">
            <span>{{ getTensDigit(secondsLeft) }}</span>
          </span>
        </div>

        <div class="figure sec-2">
          <span class="top"
            [ngClass]="{ 'animation-top-delay': changeSecondsUnit }">{{ getUnitNumber(secondsLeft) }}</span>
          <span class="top-back" [ngClass]="{ 'animation-top-back-delay': changeSecondsUnit }">
            <span>{{ getUnitNumber(secondsLeft) }}</span>
          </span>
          <span class="bottom">{{ getUnitNumber(secondsLeft) }}</span>
          <span class="bottom-back">
            <span>{{ getUnitNumber(secondsLeft) }}</span>
          </span>
        </div>
        <span class="count-title">{{
          "shared.trial-video-timer.seconds" | translate
        }}</span>
      </div>
    </div>
  </div>
</div>