import { PayActions, PayActionsTypes } from "../actions";
import { PAYMENT_TYPES, SepaDataModel } from "src/app/domains/activation/models";

export interface State {
  stripeToken: string;
  couponCode?: string;
  stripePublicKey: string;
  sepaData: SepaDataModel;
  paymentType: PAYMENT_TYPES;
}

export const initialState: State = {
  stripeToken: "",
  stripePublicKey: "",
  sepaData: null,
  paymentType: null,
};

export function reducer(state: State = initialState, action: PayActions) {
  switch (action.type) {
    case PayActionsTypes.StoreToken:
      return {
        ...state,
        stripeToken: action.token,
        ...(action.paymentType && { paymentType: action.paymentType }),
        ...(action.couponCode && { couponCode: action.couponCode })
      };
    case PayActionsTypes.StorePayment:
      return {
        ...state,
        stripeToken: action.stripeToken || null,
        sepaData: action.sepaData || null,
        paymentType: action.paymentType,
        couponCode: action.couponCode || null
      };
    case PayActionsTypes.StoreStripePublicKey:
      return {
        ...state,
        stripePublicKey: action.stripeKey
      };
    case PayActionsTypes.StoreCoupon:
      return {
        ...state,
        couponCode: action.couponId || null
      }

    default:
      return state;
  }
}
