import { Component, OnInit } from '@angular/core';
import { AppFacade } from 'src/app/domains/app/store';

@Component({
  selector: 'onb-verify-benefit',
  templateUrl: './benefit.component.html',
  styleUrls: ['./benefit.component.scss']
})
export class BenefitComponent implements OnInit {
  public trialPlan: boolean = false;

  constructor(private appFacade: AppFacade) {}

  ngOnInit() {
    this.appFacade.data$.subscribe((data: any) => {
      if(data) {
        if(data.freetrialPlan) {
          this.trialPlan = true;
        }
      }
    })
  }
}
