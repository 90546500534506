import { FlowConfig }from './flow.model';

export const flowStepsConfigIT: FlowConfig = {
  attiva_ora: {
    steps: [
      "signup",
      "verify-phone",
      "verify-sms",
      "info",
      "account-number",
      "choose-plan8",
      "wifi-full",
      "pay",
      "activation-paid"
    ],
    data: {
      sendSms: true,
      initialCountry: 'IT',
      servedCountries: [
        {
          iso2: 'IT'
        },
        {
          iso2: 'GB',
        },
        {
          iso2: 'US'
        }
      ]
    }
  },
  attiva_ora_partner: {
    steps: [
      "signup",
      "verify-phone",
      "info",
      "account-number",
      "choose-plan7",
      "pay",
      "activation-paid"
    ],
    data: {
      isPartnerFlow: true,
      redirectToUrl: "prova_gratis",
      sendSms: false,
      initialCountry: 'IT',
      servedCountries: [
        {
          iso2: 'IT'
        },
        {
          iso2: 'GB',
        },
        {
          iso2: 'US'
        }
      ]
    }
  },
  prova_gratis: {
    steps: [
      "signup",
      "verify-phone",
      "verify-sms",
      "info",
      "starter-kit",
      "assign-starter-kit",
      "payment-method",
      "activation"
    ],
    data: {
      freetrialPlan: {
        it: "it-trial-v2",
        gb: "gb-trial-v1",
        us: "us-trial-v1"
      },
      askPaymentMethod: true,
      sendSms: true,
      initialCountry: 'IT',
      servedCountries: [
        {
          iso2: 'IT'
        }
      ]
    }
  },
  prova_gratis_specialista_1: {
    steps: [
      "welcome-specialist",
      "signup",
      "verify-phone",
      "verify-sms",
      "info",
      "starter-kit",
      "assign-starter-kit",
      "payment-method",
      "activation"
    ],
    data: {
      freetrialPlan: {
        it: "it-trial-v2",
        gb: "gb-trial-v1",
        us: "us-trial-v1"
      },
      askPaymentMethod: false,
      linkTokenExp: "prova_gratis",
      linkTokenNotExist: "prova_gratis",
      sendSms: true,
      initialCountry: 'IT',
      servedCountries: [
        {
          iso2: 'IT'
        }
      ]
    }
  },
  prova_gratis_partner: {
    steps: [
      "signup",
      "verify-phone",
      "info",
      "payment-method",
      "activation"
    ],
    data: {
      freetrialPlan: {
        it: "it-trial_partner-v1",
        gb: "gb-trial_partner-v1",
        us: "us-trial_partner-v1"
      },
      askPaymentMethod: false,
      isPartnerFlow: true,
      sendSms: false,
      redirectToUrl: "prova_gratis",
      initialCountry: 'IT',
      servedCountries: [
        {
          iso2: 'IT'
        }
      ]
    }
  },
  attivazione_offerta: {
    steps: [
      "welcome-offer",
      "signup",
      "verify-phone",
      "verify-sms",
      "info",
      "account-number",
      "choose-plan6",
      "wifi",
      "pay",
      "activation-paid"
    ],
    data: {
      sendSms: true,
      initialCountry: 'IT',
      servedCountries: [
        {
          iso2: 'IT'
        },
        {
          iso2: 'GB',
        },
        {
          iso2: 'US'
        }
      ]
    }
  },
  attivazione_offerta_wifi: {
    steps: [
      "welcome-offer",
      "signup",
      "verify-phone",
      "verify-sms",
      "info",
      "account-number",
      "choose-plan6",
      "wifi",
      "pay",
      "activation-paid"
    ],
    data: {
      sendSms: true,
      initialCountry: 'IT',
      servedCountries: [
        {
          iso2: 'IT'
        },
        {
          iso2: 'GB',
        },
        {
          iso2: 'US'
        }
      ]
    }
  },
  attivazione_offerta_wifi_full: {
    steps: [
      "welcome-offer",
      "signup",
      "verify-phone",
      "verify-sms",
      "info",
      "account-number",
      "choose-plan6",
      "wifi-full",
      "pay",
      "activation-paid"
    ],
    data: {
      sendSms: true,
      initialCountry: 'IT',
      servedCountries: [
        {
          iso2: 'IT'
        },
        {
          iso2: 'GB',
        },
        {
          iso2: 'US'
        }
      ]
    }
  },
  attiva_ora_specialista: {
    steps: [
    "welcome-direct-specialist",
    "signup",
    "verify-phone",
    "verify-sms",
    "info",
    "account-number",
    "choose-plan8",
    "pay",
    "activation-paid"
    ],
    data: {
      sendSms: true,
      initialCountry: 'IT',
      servedCountries: [
        {
          iso2: 'IT'
        },
        {
          iso2: 'GB',
        },
        {
          iso2: 'US'
        }
      ]
    }
  },
  attiva_ora_specialista_wifi: {
    steps: [
    "welcome-direct-specialist",
    "signup",
    "verify-phone",
    "verify-sms",
    "info",
    "account-number",
    "choose-plan8",
    "wifi",
    "pay",
    "activation-paid"
    ],
    data: {
      sendSms: true,
      initialCountry: 'IT',
      servedCountries: [
        {
          iso2: 'IT'
        },
        {
          iso2: 'GB',
        },
        {
          iso2: 'US'
        }
      ]
    }
  },
  attiva_ora_specialista_wifi_full: {
    steps: [
    "welcome-direct-specialist",
    "signup",
    "verify-phone",
    "verify-sms",
    "info",
    "account-number",
    "choose-plan8",
    "wifi-full",
    "pay",
    "activation-paid"
    ],
    data: {
      sendSms: true,
      initialCountry: 'IT',
      servedCountries: [
        {
          iso2: 'IT'
        },
        {
          iso2: 'GB',
        },
        {
          iso2: 'US'
        }
      ]
    }
  },
  attiva_ora_partner_specialista: {
    steps: [
    "welcome-direct-specialist",
    "signup",
    "verify-phone",
    "info",
    "account-number",
    "choose-plan7",
    "pay",
    "activation-paid"
    ],
    data: {
      isPartnerFlow: true,
      redirectToUrl: "prova_gratis",
      sendSms: false,
      initialCountry: 'IT',
      servedCountries: [
        {
          iso2: 'IT'
        },
        {
          iso2: 'GB',
        },
        {
          iso2: 'US'
        }
      ]
    }
  },
  prova_gratis_partner_specialista: {
    steps: [
    "welcome-specialist",
    "signup",
    "verify-phone",
    "info",
    "payment-method",
    "activation"
    ],
    data: {
      freetrialPlan: {
        it: "it-trial_partner-v1",
        gb: "gb-trial_partner-v1",
        us: "us-trial_partner-v1"
      },
      askPaymentMethod: false,
      isPartnerFlow: true,
      sendSms: false,
      redirectToUrl: "prova_gratis_partner",
      initialCountry: 'IT',
      servedCountries: [
        {
          iso2: 'IT'
        }
      ]
    }
  },
  attiva_ora_fluida: {
    steps: [
      "signup",
      "verify-phone",
      "verify-sms",
      "info",
      "account-number",
      "choose-plan6",
      "pay",
      "activation-paid"
    ],
    data: {
      sendSms: true,
      initialCountry: 'IT',
      servedCountries: [
        {
          iso2: 'IT'
        }
      ]
    }
  }
}
