
import { StarterKitActions, StarterKitActionsTypes } from "../actions";
import { AssignedUser, Equipment } from '../../models';

export interface State {
  id: string;
  name: string;
  description: string;
  infoURL: string;
  equipments: Equipment[];
  addon_id: string;
}

export const initialState: State = {
  id: "",
  name: "",
  description: "",
  infoURL: "",
  equipments: [],
  addon_id: ""
};

export function reducer(
  state: State = initialState,
  action: StarterKitActions
): State {
  switch (action.type) {
    case StarterKitActionsTypes.storeStarterKit: {
      return {
        ...state,
        id: action.id,
        addon_id: action.addon_id
      };
    }
    case StarterKitActionsTypes.removeStarterKit: {
      return {
        ...state,
        id: action.id,
        addon_id: action.addon_id
      };
    }
    case StarterKitActionsTypes.storeStarterKitEquipments: {
      return {
        ...state,
        equipments: action.equipments
      }
    }
    default:
      return state;
  }
}