import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Logger } from "./core/logger/logger.service";
import { I18nService } from "./core/i18n";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { AppFacade } from "./domains/app/store/facades";
import { Intercom } from "ng-intercom";
import { Title } from '@angular/platform-browser';
import { AppService } from './domains/app/services/app.service';
import { PayService } from './domains/pay/service/pay.service';
import { ChoosePlanFacade } from './domains/choose-plan/store/facade';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  logger = new Logger("AppComponent");

  constructor(
    public intercom: Intercom,
    private i18n: I18nService,
    private appFacade: AppFacade,
    private router: Router,
    private titleService: Title,
    private translate: TranslateService,
    private appService: AppService,
    private payService: PayService,
    private planFacade: ChoosePlanFacade
  ) { }

  ngOnInit() {
    // hack manually reload router config, to allow generated to be used
    // TODO: find a better way to do this
    this.router.config = this.appService.generateRoutes(this.router.config);
    this.i18n.init(
      environment.defaultLanguage,
      environment.supportedLanguages
    )

    if (environment.production) {
      Logger.enableProductionMode();
    }
    this.logger.info(this.router.config);
    this.appFacade.locale(this.i18n.language);

    this.appFacade.data$.subscribe((data: any) => {
      const lang = navigator.language || navigator['userLanguage'] || navigator['browserLanguage'];
      if (data && data.freetrialPlan) {
        if(lang.includes('it')) {
          this.titleService.setTitle('Prova Voxloud');
        } else {
          this.titleService.setTitle('Try Voxloud');
        }
      } else {
        if(lang.includes('it')) {
          this.titleService.setTitle('Attiva Voxloud');
        } else {
          this.titleService.setTitle('Activate Voxloud');
        }
      }
      if(data && data.internalPlan) {
        this.payService.getPlanById(data.internalPlan).subscribe((res: any) => {
          const payload = {
            planId: res.id || null,
            description: res.description || null,
            price: res.price || null,
            discount: res.discount || null,
            quantity: res.quantity || 1
          };
          this.planFacade.savePlanId(payload);
        })
      }
    });

    // Integrate intercom
    this.intercom.boot({
      app_id: environment.intercom_app_id,
      // Supports all optional configuration.
      widget: {
        activator: "#intercom"
      }
    });

    this.intercom.update({
      language_override: this.i18n.language
    });

    this.translate.onLangChange.subscribe((langEvent: LangChangeEvent) => {
      this.intercom.update({
        language_override: langEvent.lang
      });
    });
    // Google Tag manager
    let element = document.createElement('script');
    element.innerHTML = `(function(w, d, s, l, i) {w[l] = w[l] || [];w[l].push({'gtm.start': new Date().getTime(),event: 'gtm.js'});var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l: '';j.async = true;j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;f.parentNode.insertBefore(j, f);})(window,document, 'script', 'dataLayer', '${environment.gtmpixelCode}');`;
    document.head.appendChild(element);
  }
}
