import { Injectable } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Logger } from '../logger/';
import { Languages } from 'src/app/shared/layout/header/languages.model';
import { AppFacade } from 'src/app/domains/app/store';
import { Observable } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

const log = new Logger('I18N');

@Injectable({
  providedIn: 'root'
})
export class I18nService {
  languageKey = 'language'; //for localStorage
  defaultLanguage: string;
  supportedLanguages: Array<string>;
  currentFlow = '';
  constructor(private translate: TranslateService, private facade: AppFacade, private router: Router) { }

  init(defaultLanguage: string, supportedLanguages: Array<Languages>) {
    this.defaultLanguage = defaultLanguage;
    let sLanguages: Array<string> = [];
    for (const supported of supportedLanguages) {
      sLanguages.push(supported.locale);
    }
    this.supportedLanguages = sLanguages;
    this.language = '';
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setLanguageKey(event.lang);
    });
    this.currentFlow = document.location.pathname.split('/').slice(1)[0];
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(
      (e: NavigationEnd) => {
        const newFlow = e.urlAfterRedirects.split('/')[1];
        if (newFlow !== this.currentFlow) {
          this.currentFlow = newFlow;
          this.resetLang();
        }
      }
    )
  }

  set language(language: string) {
    log.debug('Setting new language: ' + language);
    language = this.getCurrentLanguage(language);
    log.debug('Using: ' + language);
    this.translate.use(language);
  }

  get language(): string {
    return this.translate.currentLang;
  }

  get languageChange(): Observable<LangChangeEvent> {
    return this.translate.onLangChange;
  }

  resetLang() {
    // Discard all languages to avoid keeping old version of languages not in use
    this.supportedLanguages.forEach((language: string) => {
      if (language === this.language) {
        this.translate.reloadLang(language);
      } else {
        this.translate.resetLang(language);
      }
    })
  }

  private getCurrentLanguage(language: string): string {
    language =
      language || this.getLanguageKey() || this.translate.getBrowserLang();

    let isLanguageSupported = this.supportedLanguages.includes(language);

    return isLanguageSupported ? language : this.defaultLanguage;
  }

  private getLanguageKey(): string {
    log.debug('Getting saved language');
    return localStorage.getItem(this.languageKey);
  }

  private setLanguageKey(current: string): void {
    log.debug('Storing language: ' + current);
    this.facade.locale(current);
    localStorage.setItem(this.languageKey, current);
  }
}
