import { Action } from '@ngrx/store';
import { User } from '../../model';

export enum SignupActionTypes {
  SaveSignup = '[Signup] SaveSignup'
}

export class SaveSignup implements Action {
  readonly type = SignupActionTypes.SaveSignup;
  constructor(public payload: User) {}
}

export type SignupActions = SaveSignup;
