import { Action } from '@ngrx/store';
import { PlanDto } from '../../models';
import { State } from '../reducers';

export enum ChoosePlanActionTypes {
  SavePlanId = '[Choose Plan] Save Plan Id',
  SavePlanQuantity = '[Choose Plan] Save Plan Quantity'
}

export class SavePlanId implements Action {
  readonly type = ChoosePlanActionTypes.SavePlanId;
  constructor(readonly payload: State) {}
}

export class SavePlanQuantity implements Action {
  readonly type = ChoosePlanActionTypes.SavePlanQuantity;
  constructor(readonly quantity: number) {}
}

export type ChoosePlanActions = SavePlanId | SavePlanQuantity;
