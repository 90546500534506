import { State, reducer } from './info.reducers';
import { ActionReducerMap } from '@ngrx/store';

export interface InfoState {
  status: State;
}

export const infoReducer: ActionReducerMap<InfoState> = {
  status: reducer
};

export * from './info.reducers';
