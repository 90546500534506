import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";

import { environment } from "src/environments/environment";
import { AppReducer } from "./app/store/reducers";
import { AppEffects } from "./app/store/effects";
import { LocaleInterceptor } from "./app/interceptors/locale.interceptors";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppService } from "./app/services/app.service";
import { ActivationDomainModule } from "./activation/activation.module";
import { AddonDomainModule } from "./addon/addon.module";
import { ChoosePlanDomainModule } from "./choose-plan/choose-plan.domain";
import { InfoDomainModule } from "./info/info.module";
import { SignupDomainModule } from "./signup/signup.module";
import { StarterKitDomainModule } from "./starter-kit/starter-kit.module";
import { VerifyPhoneDomainModule } from "./verify-phone/verify-phone.module";
import { PayDomainModule } from "./pay/pay.domain";
import { CoverageDomainModule } from "./coverage/coverage.module";
import { AccountNumberDomainModule } from './account-number/account-number.module';
import { AuthDomainModule } from './auth/auth.module';

@NgModule({
  imports: [
    StoreModule.forRoot(AppReducer),
    EffectsModule.forRoot([AppEffects]),
    StoreDevtoolsModule.instrument({
      name: "Voxloud",
      maxAge: 10,
      logOnly: environment.production
    }),
    ActivationDomainModule,
    AddonDomainModule,
    ChoosePlanDomainModule,
    InfoDomainModule,
    PayDomainModule,
    SignupDomainModule,
    StarterKitDomainModule,
    VerifyPhoneDomainModule,
    CoverageDomainModule,
    AccountNumberDomainModule,
    AuthDomainModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LocaleInterceptor,
      multi: true
    },
    AppService
  ]
})
export class DomainsModule {}
