import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, concatMap, map, withLatestFrom } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { TokenResponse } from '../models/auth.model';


@Injectable()
export class AuthService {
  constructor( private http: HttpClient ) {}

  public validateCfToken(token: string) {
    return this.http.post<TokenResponse>(`${environment.legacyEndpoint}/api/validate-cf-token`,
      { token }
    );
  }
}
