import { Action } from "@ngrx/store";
import { Addon, Addons } from "../../model";

export enum AddonActionsTypes {
  storeAddons = "[Addon] Store Addons",
  storeNumberAddon = "[Addon] Store Number Addon",
  storeSkAddons = "[Addon] Store Starter Kits Addons"
}

export class StoreAddons implements Action {
  readonly type = AddonActionsTypes.storeAddons;
  constructor(readonly payload: Addons) {}
}

export class StoreNumberAddon implements Action {
  readonly type = AddonActionsTypes.storeNumberAddon;
  constructor(readonly payload: Addon) { }
}

export class StoreSkAddons implements Action {
  readonly type = AddonActionsTypes.storeSkAddons;
  constructor(readonly payload: Addons) { }
}

export type AddonActions = StoreAddons | StoreNumberAddon | StoreSkAddons;
