import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { WifiActivationRequest } from "../../models/coverage.model";
import { SaveCoverageData } from "../actions/coverage.actions";
import { CoverageStoreState } from "../reducers";
import { getNecessaryData, selectCoverageData } from "../selectors/coverage.selectors";

@Injectable()
export class CoverageFacade{

    coverageData$ = this.store.pipe(select(selectCoverageData));
    coverageNecessaryData$ = this.store.pipe(select(getNecessaryData));

    constructor(private store: Store<CoverageStoreState>){}

    saveCoverageData(data: WifiActivationRequest){
        this.store.dispatch(new SaveCoverageData(data));
    }
}