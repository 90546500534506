import { Component, Input, OnInit } from '@angular/core';
import { ReviewFilters, ReviewsResponse } from 'ng-voxloud';
import { ReviewsService } from '../services/reviews.service';
import { Observable } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import { PLATFORMS } from '../services/reviews.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'onb-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit {
  @Input() language: string = 'it';
  @Input() theme: string = 'white';
  @Input() platform: string = 'CAPTERRA';
  loading: boolean = false;
  reviewsResponse$: Observable<ReviewsResponse>;
  pageSize: number = 1000;
  page: number = 0;
  filters: ReviewFilters;
  loadBoth: boolean = false;
  errorMessage: string;
  errorCode: number;
  retryBtn: string;

  constructor(private reviewService: ReviewsService, private translateService: TranslateService) { }

  ngOnInit() {
    this.getAllReviews()
    this.isReviewsListEpmty();
  }

  getAllReviews() {
    this.filters = {
      company_id: null,
      is_company_associated: null,
      timestamp_from: null,
      timestamp_to: null,
      platform: this.platform == PLATFORMS.BOTH ? '' : this.platform.toUpperCase(),
      rating_from: '4',
      rating_to: null
    }
    this.loading = true;
    this.reviewsResponse$ = this.reviewService.getAllReviews(
      this.page,
      this.pageSize,
      this.filters
    ).pipe(
      catchError((error) => {
        this.errorMessage = this.translateService.instant('shared.review.errors.api-error');
        this.retryBtn = this.translateService.instant('shared.review.errors.retry-btn');
        this.errorCode = 500;
        throw error;
      }),
      shareReplay(1)
    )
    this.loading = false;
  }


  private isReviewsListEpmty() {
    this.reviewsResponse$.subscribe(res => {
      if (!res.content.length) {
        this.errorMessage = this.translateService.instant('shared.review.errors.empty-list');
      }
    })
  }
}
