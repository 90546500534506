import { createFeatureSelector, createSelector } from '@ngrx/store';
import { VerifyPhoneState, State } from '../reducers';

export const selectVerifyPhoneState = createFeatureSelector<VerifyPhoneState>(
  'verifyPhone'
);

export const selectPhoneNumberStatus = createSelector(
  selectVerifyPhoneState,
  (state: VerifyPhoneState) => state.status
);

export const selectPhoneNumber = createSelector(
  selectPhoneNumberStatus,
  (state: State) => state.number
);

export const selectCode = createSelector(
  selectPhoneNumberStatus,
  (state: State) => state.code
);

export const selectToken = createSelector(
  selectPhoneNumberStatus,
  (state: State) => state.token
);

export const selectLoading = createSelector(
  selectPhoneNumberStatus,
  (state: State) => state.loading
);

export const selectError = createSelector(
  selectPhoneNumberStatus,
  (state: State) => state.error
);
