import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'onb-verify-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleVerifyComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
