import { SignupActions, SignupActionTypes } from '../actions';

export interface State {
  fullname: string;
  email: string;
  password: string;
  terms: boolean;
}

export const initialState: State = {
  fullname: '',
  email: '',
  password: '',
  terms: false
};

export function reducer(state = initialState, action: SignupActions) {
  switch (action.type) {
    case SignupActionTypes.SaveSignup: {
      let user = action.payload;
      return {
        ...state,
        fullname: user.fullname,
        email: user.email,
        password: user.password,
        terms: user.terms
      };
    }

    default:
      return state;
  }
}
